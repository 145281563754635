import React from 'react'
import * as AntdComponents from 'antd'
import { useNavigate } from 'react-router-dom'
import { RouterKeys } from '../Routes/RouterKeys'
import CommonHeader from '../screen/Auth/CommonHeader'
import CustomButton from '../components/CustomButton'
const Continue = () => {
  const navigate = useNavigate()
  return (
    <div className='auth-main-div text-center'>
      <CommonHeader />
      <CustomButton  htmlType='button' title='Continue' className={'mt-12'} onClick={() => navigate(`/${RouterKeys.Auth.REGISTER_LOGIN}`)} />
      <div className="mt-5 text-center">
        <AntdComponents.Typography className='paragraph'>By pressing the button continue, you agree to our</AntdComponents.Typography>
        <AntdComponents.Typography className='paragraph'><span className='paragraph font-bold text-TermsColor underline underline-offset-2'>Terms of Service</span> and
          <span className='paragraph font-bold text-TermsColor underline underline-offset-2'> Privacy Policy.</span></AntdComponents.Typography>
      </div>
    </div>
  )
}

export default Continue
