import React from 'react'
import { STATIC_IMAGES } from '../../utils/StaticImages'
import * as AntdComponents from 'antd'
import { useNavigate } from 'react-router-dom'
import { RouterKeys } from '../../Routes/RouterKeys'
import { useDispatch } from 'react-redux'
import { UserRegisterData } from '../../redux/slices/authSlice'
import CommonHeader from './CommonHeader'
import CustomButton from '../../components/CustomButton'
import SocialLogin from './socialLogin/SocialLogin'
const Register = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = (values) => {
        let payload = values
        console.log("payload", payload);
        dispatch(UserRegisterData(payload))
        navigate(`/${RouterKeys.Auth.CREATE_PASSWORD}`)
    }
    return (
        <div className='auth-main-div'>
            <CommonHeader />
            <div className='text-center auth-form shadow-formShadow rounded-10 p-4'>
                <div className="bg-Grey rounded-10 p-3">
                    <AntdComponents.Typography className='text-center titleSmall'>Create your account</AntdComponents.Typography>
                    <SocialLogin/>
                </div>
                <div className="">
                    <div className="inputs-container">
                    <AntdComponents.Typography className='titleMedium text-center mb-4'>Register with email</AntdComponents.Typography>
                        <AntdComponents.Form name="Auth" initialValues={{ remember: true, }} onFinish={handleSubmit} requiredMark={false} className='mt-2' layout='vertical'>
                            <AntdComponents.Row gutter={{ xs: 0, sm: 0, md: 20, lg: 20 }}>
                                <AntdComponents.Col xs={24} sm={24} md={12} lg={12}>
                                    <AntdComponents.Form.Item name="first_name" autoComplete="off"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter first name!',
                                            },
                                        ]}>
                                        <AntdComponents.Input className='input-box' placeholder='First Name' />
                                    </AntdComponents.Form.Item>
                                </AntdComponents.Col>
                                <AntdComponents.Col xs={24} sm={24} md={12} lg={12}>
                                    <AntdComponents.Form.Item name="last_name" autoComplete="off"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter last name!',
                                            },
                                        ]}>
                                        <AntdComponents.Input className='input-box' placeholder='Last Name' />
                                    </AntdComponents.Form.Item>
                                </AntdComponents.Col>
                            </AntdComponents.Row>
                            <AntdComponents.Form.Item name="email" autoComplete="off"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please Enter your email!',
                                    },
                                ]}>
                                <AntdComponents.Input className='input-box' placeholder='name@domain.com' prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.EMAIL} size={30} shape='square' />} />
                            </AntdComponents.Form.Item>
                            <CustomButton title='Register' className='mt-10 mb-4' htmlType='submit' />
                        </AntdComponents.Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
