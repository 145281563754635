import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../utils/StaticImages'
import { RouterKeys } from '../Routes/RouterKeys'
export const SidebarList = [
    {
        id: 'home',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.HomeDark}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.HomeDark}/>,
        title: "Home",
        routes: RouterKeys.NON_Auth.HOME.HOME,
    },
    {
        id: 'tutorial',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.Tutorial}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.Tutorial}/>,
        title: "Tutorials",
        routes: RouterKeys.NON_Auth.TUTORIALS.TUTORIAL_TRAINING,
    },
    {
        id: 'Contact',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.CONTACT}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.CONTACT}/>,
        title: "Contact",
        routes: RouterKeys.NON_Auth.CONTACTS.CONTACTS,
    },
    {
        id: 'Subscription',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.SUBSCRIPTION}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR?.SUBSCRIPTION}/>,
        title: "Subscription",
        routes: RouterKeys.NON_Auth.SUBSCRIPTION.SUBSCRIPTION,
    },
    {
        id: 'Alerts',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.WARNING}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.WARNING}/>,
        title: "Alerts",
        routes: RouterKeys.NON_Auth.ALERTS.ALERTS,
    },
    {
        id: 'Security',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR.SECURITY}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR.SECURITY}/>,
        title: "Security",
        routes: RouterKeys.NON_Auth.SECURITY.SECURITY,
    },
    {
        id: 'Feedback',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR.FEEDBACK}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.SIDEBAR.FEEDBACK}/>,
        title: "Support & Feedback",
        routes: RouterKeys.NON_Auth.GENERAL.SUPPORT_FEEDBACK,
    },
    {
        id: 'Trash',
        active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.DELETE}/>,
        in_active_icon: <AntdComponents.Avatar shape='square' size={35} src={STATIC_IMAGES?.DELETE}/>,
        title: "Trash",
        routes: RouterKeys.NON_Auth.TRASH.TRASH,
    },
]