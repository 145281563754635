import React from 'react'
import CommonHeader from './CommonHeader'
import * as AntdComponents from 'antd'

const MaxAttempt = () => {
    return (
        <div className='auth-main-div'>
            <CommonHeader />
            <div className='text-center auth-form rounded-10 shadow-formShadow p-4 pb-10'>
                <div className="">
                    <AntdComponents.Typography className='titleMedium text-center mb-4'>Incorrect login</AntdComponents.Typography>
                    <AntdComponents.Typography className='paragraph mt-4'>You have <span className='font-bold'>maxed out the number of login</span><br /><span className='font-bold'>attempts.</span></AntdComponents.Typography>
                    <AntdComponents.Typography className='paragraph mt-4'>If you have forgotten your login, please email<br />
                        <span className='font-bold text-TermsColor underline underline-offset-2'>Support@vaultpd.com.</span>
                    </AntdComponents.Typography>

                </div>
            </div>
        </div>
    )
}

export default MaxAttempt
