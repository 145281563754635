import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { useSelector } from 'react-redux'
import CustomButton from '../../../components/CustomButton'

const Security = () => {
    const userData = useSelector((state) => state?.auth?.auth_data)
    return (
        <div className='content_common'>
            <div className="text-center">
                <AntdComponents.Typography className='titleMedium text-center'>Security</AntdComponents.Typography>
                <AntdComponents.Avatar src={STATIC_IMAGES.SIDEBAR.SECURITY} size={150} />
            </div>
            <div className="grid place-items-center">
                <div className="">
                    {/* 2 factor */}
                    <div className="mt-2">
                        <AntdComponents.Typography className='titleSmall'>2 Factor authentication</AntdComponents.Typography>
                        <div className="flex items-center gap-2 ps-6">
                            <AntdComponents.Switch />
                            <AntdComponents.Typography className='paragraph'>Enable</AntdComponents.Typography>
                        </div>
                        <div className="flex gap-2 ps-6 mt-3">
                            <AntdComponents.Avatar src={STATIC_IMAGES.EDIT} shape='square' size={30} />
                            <AntdComponents.Typography className='paragraph'>Connected to this <span className='font-semibold'>email-address:</span> {userData?.email}</AntdComponents.Typography>
                        </div>
                    </div>
                    {/* communication method */}
                    <div className="mt-5">
                        <AntdComponents.Typography className='titleSmall'>Preferred communication method</AntdComponents.Typography>
                        <div className="ps-6">
                            <div className="">
                                <AntdComponents.Radio className='paragraph'>Text message</AntdComponents.Radio>
                            </div>
                            <div className="mt-2">
                                <AntdComponents.Radio className='paragraph'>Email</AntdComponents.Radio>
                            </div>
                        </div>
                    </div>
                    {/* automatic logout */}
                    <div className="mt-2">
                        <AntdComponents.Typography className='titleSmall'>Automatic log out</AntdComponents.Typography>
                        <div className="flex items-center gap-2 ps-6">
                            <AntdComponents.Switch />
                            <AntdComponents.Typography className='paragraph'>Enable</AntdComponents.Typography>
                        </div>
                        <div className="flex items-center gap-2 ps-6 mt-3">
                            <AntdComponents.Avatar src={STATIC_IMAGES.EDIT} shape='square' size={30} />
                            <AntdComponents.Typography className='paragraph'>Set to <span className='font-semibold'>log out</span> after <span className='font-semibold'>10 Minutes</span></AntdComponents.Typography>
                        </div>
                    </div>
                </div>
                <div className="">
                    <CustomButton title='Update' className={'my-8'} />
                </div>
            </div>
        </div>
    )
}

export default Security
