import React from 'react'
import * as AntdComponents from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactIcons } from '../../../utils/ReactIcons';
import { STATIC_IMAGES } from '../../../utils/StaticImages';
import CommonDocumentDetails from '../../../components/CommonDocumentDetails';
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice';
import { AUTH } from '../../../utils/Endpoints';
import { QueryKeys } from '../../../utils/RTKKeys';
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader';
import CustomButton from '../../../components/CustomButton';
import UploadModal from '../../../components/UploadModal';
import UploadStatus from './UploadStatus';
import NoData from '../../../components/NoData';
// import CustomToast from '../../../utils/CustomToast';
// import SkeletonLoader from '../../../utils/Loader/SkeletonLoader';


const popoverArr = [
    {
        title: 'Share File',
        icon: STATIC_IMAGES.SHARE,
        type: 'share'
    },
    {
        title: 'Delete File',
        icon: STATIC_IMAGES.DELETE,
        type: 'delete'
    },
    {
        title: 'View file history',
        icon: STATIC_IMAGES.HISTORY,
        type: 'history'
    },
    {
        title: 'Rename File',
        icon: STATIC_IMAGES.EDIT,
        type: 'edit'
    },
]
const FolderDocuments = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const folderId = searchParam.get('folder_id')
    const folderName = searchParam.get('folder_name')
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false)
    const [blobImages, setBlobImages] = React.useState([])
    const [completeStatus, setCompleteStatus] = React.useState(false)
    const [finalUploadFiles, setFinalUploadFiles] = React.useState([])


    // folder detail
    const { data: folderData, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.HOME.FOLDER.GET_FOLDER_DETAIL,
        params: { folder_id: folderId },
        key: QueryKeys.FOLDER_DETAIL,
    },
        { skip: !folderId, refetchOnMountOrArgChange: true }
    )
    // uploading file
    const [triggerDocumentUpload, { data: uploadedImages, isLoading: docUploading, isSuccess: docUploadSuccess, }] = useDynamicMutationMutation();
    React.useEffect(() => {
        if (folderData?.data?.result) {
            setFinalUploadFiles(folderData?.data?.result)
        }
    }, [folderData])


    return (
        <>
            {
                (!docUploading && !completeStatus) ?
                    <div className='content_common'>
                        <div className="flex items-start md:items-center flex-col md:flex-row justify-between gap-3 md:gap-0 mt-5">
                            <div className="flex items-center gap-2">
                                <ReactIcons.BackIcon onClick={() => { navigate(-1) }} />
                                <AntdComponents.Typography className='titleMedium text-center capitalize'>{folderName}</AntdComponents.Typography>
                            </div>
                            {/* <AntdComponents.Typography className=''></AntdComponents.Typography> */}
                            <CustomButton title={'Add Files'} className={'w-40 h-12'} onClick={() => setUploadModalOpen(true)} />
                        </div>
                        <div className="my-10">
                            {
                                isLoading ?
                                    <AntdComponents.Row gutter={[20, 20]}>
                                        {
                                            [1, 2, 3]?.map((_, index) => {
                                                return (
                                                    <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                                                        <SkeletonLoader />
                                                    </AntdComponents.Col>
                                                )
                                            })
                                        }
                                    </AntdComponents.Row>
                                    :
                                    <>
                                        {
                                            folderData.data?.result?.length === 0 ? <NoData />
                                                :
                                                <CommonDocumentDetails data={finalUploadFiles} setFinalUploadFiles={setFinalUploadFiles} popoverArr={popoverArr} folderId={folderId} byDetailPage={false} colNum={8} />
                                        }
                                    </>
                            }
                        </div>
                    </div>
                    :
                    <>
                        {
                            ((docUploading || docUploadSuccess) && completeStatus) &&
                            <UploadStatus docUploading={docUploading} setCompleteStatus={setCompleteStatus} docUploadSuccess={docUploadSuccess} uploadingImages={blobImages} uploadedImages={uploadedImages?.data || []} />

                        }
                    </>
            }
            {/* Upload modal */}
            {
                uploadModalOpen &&
                // <UploadModal uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen} documentAdd={triggerDocumentUpload} setBlobImages={setBlobImages} setCompleteStatus={setCompleteStatus} folderId={folderId} isFolder={false} />
                <UploadModal uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen} setBlobImages={setBlobImages} documentAdd={triggerDocumentUpload} setCompleteStatus={setCompleteStatus} isFolder={false} folderId={folderId} />
            }
        </>
    )
}

export default FolderDocuments
