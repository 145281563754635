import React from 'react'
import { ReactIcons } from '../../../utils/ReactIcons'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../../components/CustomButton'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useDynamicMutationMutation } from '../../../redux/service/apiSlice'
import UploadStatus from '../Home/UploadStatus'
import UploadModal from '../../../components/UploadModal'

const array = [
    {
        icon: STATIC_IMAGES.TUTORIAL.ADD_PERSON,
        title: 'Add Person',
        description: 'Learn how to add a person to your account',
        route: RouterKeys.NON_Auth.CONTACTS.CONTACTS

    },
    {
        icon: STATIC_IMAGES.TUTORIAL.CHECKLIST,
        title: 'Use Checklists',
        description: 'Assemble the right documents in order',
        route: RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEYS
    },
]
const ShareDocument = () => {
    const navigate = useNavigate()
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false)
    const [blobImages, setBlobImages] = React.useState([])
    const [completeStatus, setCompleteStatus] = React.useState(false)

    // create folder 
    const [createFolderMutation, { isLoading: folderCreateLoading }] = useDynamicMutationMutation();
    // uploading file
    const [triggerDocumentUpload, { data: uploadedImages, isLoading: docUploading, isSuccess: docUploadSuccess, }] = useDynamicMutationMutation();
    return (
        <>
            {
                (!docUploading && !completeStatus) ?
                    <div className="bg-gray-100 relative h-screen w-full">
                        <div className="absolute right-0 top-0 h-[300px] w-full max-w-sm rounded-bl-full bg-BlueTransparent blur-3xl"></div>
                        <div className="absolute bottom-0 left-0 h-[300px] w-full max-w-sm rounded-bl-none rounded-tr-full bg-OrangeTransparent blur-3xl"></div>

                        {/* Main Content */}
                        <div className="relative flex items-center justify-center">
                            <div className='rounded-10 w-full shadow-formShadow p-4 min-h-600'>
                                <div className="back-box">
                                    <ReactIcons.BackIcon style={{ fontSize: 35, color: 'var(--defaultPurple)', cursor: 'pointer' }} onClick={() => { navigate(-1) }} />
                                    <AntdComponents.Typography className='titleMedium text-center'>Share documents</AntdComponents.Typography>
                                    <AntdComponents.Typography className=''></AntdComponents.Typography>
                                </div>
                                <div className="text-center">
                                    <div className="outline-button rounded-0.3 w-full md:w-1/3 m-auto mt-4">
                                        <div className="bg-White rounded-0.2 h-[100%] py-3">
                                            <AntdComponents.Avatar src={STATIC_IMAGES.SHARE} size={100} />
                                        </div>
                                    </div>
                                    <div className="text-center w-full mt-7">
                                        <AntdComponents.Typography className='titleMedium'>How to share documents</AntdComponents.Typography>
                                        <AntdComponents.Typography className='text-para'>In this video, we cover how to securely send documents to trusted individuals and companies for on the go, from your phone.</AntdComponents.Typography>
                                        <CustomButton title='Upload' className='mt-6 mb-3' onClick={() => setUploadModalOpen(true)} />
                                        <div className="mt-3 w-full" >
                                            <AntdComponents.Row gutter={[16, 16]}>
                                                {
                                                    array?.map((item, index) => {
                                                        return (
                                                            <AntdComponents.Col xs={24} sm={24} md={12} lg={12} xl={8} key={index}>
                                                                <div className="outline-button rounded-0.3 mt-5" key={index} role='button' tabIndex={0}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter' || e.key === '') {
                                                                            navigate(item?.route)
                                                                        }
                                                                    }
                                                                    }
                                                                    onClick={() => navigate(item?.route)}>
                                                                    <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-normal items-center gap-0 md:gap-4 p-2 bg-White rounded-0.2 h-[100%]">
                                                                        <div className="">
                                                                            <AntdComponents.Avatar src={item?.icon} size={60} />
                                                                        </div>
                                                                        <div className="text-center md:text-start">
                                                                            <AntdComponents.Typography className='titleSmall'>{item?.title}</AntdComponents.Typography>
                                                                            <AntdComponents.Typography className='text-para'>{item?.description}</AntdComponents.Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </AntdComponents.Col>
                                                        )
                                                    })
                                                }

                                            </AntdComponents.Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {
                            ((docUploading || docUploadSuccess) && completeStatus) &&
                            <UploadStatus folderCreateLoading={folderCreateLoading} docUploading={docUploading} setCompleteStatus={setCompleteStatus} docUploadSuccess={docUploadSuccess} uploadingImages={blobImages} uploadedImages={uploadedImages?.data || []} />
                        }
                    </>
            }
            {
                uploadModalOpen &&
                <UploadModal
                    uploadModalOpen={uploadModalOpen}
                    setUploadModalOpen={setUploadModalOpen}
                    setBlobImages={setBlobImages}
                    documentAdd={triggerDocumentUpload}
                    setCompleteStatus={setCompleteStatus}
                    isFolder={true}
                    createFolderFunc={createFolderMutation}
                />
            }
        </>
    )
}

export default ShareDocument
