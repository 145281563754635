import React, { useRef, useState } from "react";

const CameraComponent = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  // Start the camera
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  // Capture image
  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the image as a data URL
      const imageData = canvas.toDataURL("image/png");
      setCapturedImage(imageData);
    }
  };

  return (
    <div>
      <h1>Camera App</h1>
      <div>
        <video ref={videoRef} style={{ width: "100%", maxWidth: "400px" }} >
          <track
            default
            kind="captions"
            srcLang="en"
            src="path/to/captions.vtt" // Replace with the actual path if available
            label="English"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="flex items-center gap-3">
      <button onClick={startCamera} className="border">Start Camera</button>
      <button onClick={captureImage} className="border">Capture Image</button>
      </div>

      {capturedImage && (
        <div>
          <h2>Captured Image:</h2>
          <img src={capturedImage} alt="Captured" style={{ width: "100%", maxWidth: "400px" }} />
        </div>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default CameraComponent;
