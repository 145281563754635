import { Vortex } from "react-loader-spinner"

const LoaderCircle = ({divHeight='60vh',height='80',width='80'}) => {
    return (
        <div className="flex items-center justify-center" style={{ height: divHeight }}>
            <Vortex
                visible={true}
                height={height}
                width={width}
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['var(--darkPurple)', 'var(--BlueColor)', 'var(--darkPurple)', 'var(--defaultPurple)', 'var(--MainPurple)', 'var(--Pink)']}
            />
        </div>
    )
}
export default LoaderCircle