import React from 'react'
import * as AntdComponents from 'antd'

const OutlineButton = ({title,onClick,className,classNameDiv='',icon,iconPosition}) => {
    return (
        <div className={`outline-button  ${classNameDiv}`}>
            <AntdComponents.Button type='ghost' icon={icon} iconPosition={iconPosition} className={`common-button w-full h-12 py-6  ${className}`} onClick={onClick}>{title}</AntdComponents.Button>
        </div>
    )
}

export default OutlineButton
