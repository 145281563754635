import logo from "../assets/logo.png"
import logoSmall from "../assets/logoSmall.png"
import Blue from "../assets/blueEllipse 1.png"
import Orange from "../assets/orangeEllipse 2.png"
import Google from "../assets/google.png"
import Apple from "../assets/apple.png"
import Email from "../assets/email.png"
import EyeOn from "../assets/eyeOn.png"
import EyeOff from "../assets/eyeOff.png"
import Key from "../assets/key.png"
import RightTick from "../assets/RightTick.png"
import WarnBoxForm from "../assets/formWarnBox.png"
import Lock from "../assets/lock.png"
import WELCOME from "../assets/welcome.png"
import AREA_PROTECT from "../assets/home.png"
import WEATHER from "../assets/weather.png"
import MEDICAL from "../assets/medical.png"
import THANKYOU from "../assets/thankYou.png"
import HomeDark from "../assets/HomeDark.png"
import HomeLight from "../assets/HomeLight.png"
import ScanLight from "../assets/scanLight.png"
import ScanDark from "../assets/scanDark.png"
import ContactLight from "../assets/contactLight.png"
import ContactDark from "../assets/ContactDark.png"
import PlusUpload from "../assets/Plus_upload.png"
import Camera from "../assets/camera.png"
import FileAdd from "../assets/filesAdd.png"
import Gallery from "../assets/gallery.png"
import Folder from "../assets/folder.png"
import LoaderImage from "../assets/loaderImage.png"
import DummyImage from "../assets/dummyImage.png"
import Checklist from "../assets/checklist.png"
import AddPerson from "../assets/add_person.png"
import Share from "../assets/share.png"
import Tutorial from "../assets/tutorial.png"
import Secure from "../assets/secure.png"
import File from "../assets/file.png"
import Edit from "../assets/edit.png"
import DeleteIcon from "../assets/deleteIcon.png"
import History from "../assets/history.png"
import MedicalCheckList from "../assets/medical_checklist.png"
import Rental from "../assets/rental.png"
import Financial from "../assets/financial.png"
import Estate from "../assets/estate_plan.png"
import Pet from "../assets/pet.png"
import Valuable from "../assets/valuable.png"
import KeyInfo from "../assets/keyInformation.png"
import Motor from "../assets/motor.png"
import LinkedinIcon from "../assets/linkedinIcon.jpg"
import Warning from "../assets/warning.png"
import Support from "../assets/support.png"
import Subscription from "../assets/subscription.png"
import Account from "../assets/account.png"
import Security from "../assets/security.png"
import Feedback from "../assets/feedback.png"
import Contact from "../assets/contact.png"
import Add from "../assets/add.png"
import Minus from "../assets/minus.png"
import RestoreComplete from "../assets/restore_success.png"
import DeleteComplete from "../assets/delete_complete.png"
import Cloud from "../assets/cloud.png"
import EditProfile from "../assets/edit_profile.png"
import Alert from "../assets/alert.png"
import Contacts from "../assets/contacts.png"
import MinusCircle from "../assets/minus_circle.png"
import AddContactCam from "../assets/add_contact_cam.png"
import Send from "../assets/send.png"
import ClearAll from "../assets/clear_all.png"
import Notification from "../assets/notification.png"
import NotificationReverse from "../assets/notification_reverse.png"
import Star from "../assets/Star.png"
import NotificationWhite from "../assets/NotificationWhite.png"
import BankSafe from "../assets/Bank Safe.png"
export const STATIC_IMAGES = {
    LOGO: logo,
    LOGO_SMALL: logoSmall,
    GOOGLE_LOGO:Google,
    LinkedinIcon:LinkedinIcon,
    Apple_LOGO:Apple,
    PlUS_UPLOAD:PlusUpload,
    CAMERA:Camera,
    FILE_ADD:FileAdd,
    GALLERY:Gallery,
    FOLDER:Folder,
    LoaderImage:LoaderImage,
    DummyImage:DummyImage,
    SECURE:Secure,
    FILE:File,
    SHARE:Share,
    EDIT:Edit,
    DELETE:DeleteIcon,
    HISTORY:History,
    WARNING:Warning,
    ADD:Add,
    MINUS:Minus,
    RESTORE_COMPLETE:RestoreComplete,
    DELETE_COMPLETE:DeleteComplete,
    CLOUD:Cloud,
    EDIT_PROFILE:EditProfile,
    ALERT_ICON:Alert,
    SEND:Send,
    NOTIFICATION:Notification,
    NOTIFICATION_REVERSE:NotificationReverse,
    CLEAR_ALL:ClearAll,
    ELLIPSE: {
        BLUE:Blue,
        ORANGE:Orange
    },
    FORM:{
        EMAIL:Email,
        EYE_ONN:EyeOn,
        EYE_OFF:EyeOff,
        KEY:Key,
        RIGHT_TICK:RightTick,
        WARN_BOX_FORM:WarnBoxForm,
    },
    COMMON:{
        Lock:Lock
    },
    ONBOARDING:{
        WELCOME:WELCOME,
        AREA_PROTECT:AREA_PROTECT,
        WEATHER:WEATHER,
        MEDICAL:MEDICAL,
        THANKYOU:THANKYOU
    },
    SIDEBAR:{
        HomeDark:HomeDark,
        HomeLight:HomeLight,
        ScanLight:ScanLight,
        ScanDark:ScanDark,
        ContactDark:ContactDark,
        ContactLight:ContactLight,
        Tutorial:Tutorial,
        SUBSCRIPTION:Subscription,
        SUPPORT:Support,
        SECURITY:Security,
        ACCOUNT:Account,
        FEEDBACK:Feedback,
        CONTACT:Contact,
    },
    TUTORIAL:{
        CHECKLIST:Checklist,
        ADD_PERSON:AddPerson,
        kEY_INFO:KeyInfo,
        MEDICAL_KEY:MedicalCheckList,
        Valuable:Valuable,
        PET:Pet,
        ESTATE:Estate,
        FINANCIAL:Financial,
        RENTAL:Rental,
        HOUSE:HomeDark,
        AUTO:Motor
    },
    CONTACTS:{
        CONTACTS:Contacts,
        VIEW:EyeOn,
        MINUS_CIRCLE:MinusCircle,
        ADD_CONTACT_CAM:AddContactCam,
    },
    SUBSCRIPTION:{
        STAR:Star,
        NOTIFICATION_WHITE:NotificationWhite,
        BANK_SAFE:BankSafe,
    }
}