import React from 'react'
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

const LinkedInComp = () => {
    //   return <LinkedInPopUp />;
    return (
        // <div className="">fsdfsfsdfsdfs</div>
        <LinkedInCallback />
    )
}

export default LinkedInComp
