import React from 'react'
import { ReactIcons } from '../utils/ReactIcons'
import CustomButton from './CustomButton'
import * as AntdComponents from 'antd'
import CommonShare from './CommonShare'
import { fileUrl } from '../utils/Constant'
import TrustedContactModal from '../screen/NonAuth/Home/TrustedContactModal'
import CustomToast from '../utils/CustomToast'

const ShareWithContact = ({ shareOptionModal, setShareOptionModal, mainOpen, setMainOpen, selectData }) => {
    const [shareModalOpen, setShareModalOpen] = React.useState(false)
    const [trustedModalOpen, setTrustedModalOpen] = React.useState(false)
    const handleNewClick = () => {
        if (selectData['folder_name']) {
            CustomToast('e', "You can't share the folders externally.")
        } else {
            setShareModalOpen(true)
        }
    }
    return (
        <div>
            {
                mainOpen &&
                <>
                    {
                        !shareModalOpen ?
                            <AntdComponents.Modal
                                centered
                                open={shareOptionModal}
                                onCancel={() => setShareOptionModal(false)}
                                footer={false}
                                width={500}
                                closeIcon={<ReactIcons.CloseIcon onClick={() => setShareOptionModal(false)}
                                />}
                            >
                                <div className="text-center">
                                    <AntdComponents.Typography className='titleMedium text-center'>Share</AntdComponents.Typography>
                                    <AntdComponents.Typography className='paragraphSmall font-bold leading-6 mt-3'>Who would you like to share this<br />information with?</AntdComponents.Typography>
                                    <div className="mt-5">
                                        <CustomButton title={'Existing Contact'} onClick={() => { setTrustedModalOpen(true); }} />
                                        <AntdComponents.Typography className='titleSmall text-Blue mt-4 cursor-pointer' onClick={() => handleNewClick()}>New contact</AntdComponents.Typography>
                                    </div>
                                </div>
                            </AntdComponents.Modal>
                            :
                            <CommonShare shareModalOpen={shareModalOpen} setShareModalOpen={setShareModalOpen} shareUrl={`${fileUrl}${selectData?.url}`} setShareOptionModal={setShareOptionModal} />
                    }
                </>
            }
            {
                (trustedModalOpen || mainOpen) &&
                <TrustedContactModal trustedModalOpen={trustedModalOpen} setTrustedModalOpen={setTrustedModalOpen} setMainOpen={setMainOpen} selectData={selectData} setShareModalOpen={setShareModalOpen} />
            }
        </div>
    )
}

export default ShareWithContact
