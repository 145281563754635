import React from 'react'
import * as AntdComponents from "antd"
import { useNavigate } from 'react-router-dom'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useMutation } from '../../../redux/hook/useApi'
import { AUTH } from '../../../utils/Endpoints'
import CustomToast from '../../../utils/CustomToast'
import { useSelector } from 'react-redux'
import CustomButton from '../../../components/CustomButton'

const ThankYou = () => {
  const onboardingAllData = useSelector((state)=>state?.onboarding_screen?.onboarding_screens)
  console.log("onboardingAllData",onboardingAllData);
  
  const { handleMutation, isLoading: submitLoading } = useMutation();
  const navigate = useNavigate()
  const handleSubmit = async() => {
    let payload ={
      "medicalVisits": onboardingAllData?.medical,
      "weatherConditions":onboardingAllData?.weather,
      "areasToProtect": onboardingAllData?.area_protect
    }
    const { data, error } = await handleMutation({
      endpoint: AUTH?.ONBOARDING.ADD_ONBOARDING_SCREEN_DATA,
      method: 'POST',
      body: payload,
    });
    if (data) {
      CustomToast('s', data?.message)
      navigate(`/${RouterKeys.NON_Auth.HOME.HOME}`)
    } else {
      CustomToast('e', error?.data?.message || 'Login failed')
    }
  }
  return (
    <div>
      <AntdComponents.Typography className='titleMedium font-bold'>Thank you</AntdComponents.Typography>
      <div className="mt-2">
        <AntdComponents.Typography className='paragraph'>We are tailoring this experiences to your unique<br /> needs.</AntdComponents.Typography>
        <AntdComponents.Typography className='titleSmall font-bold mt-4'>Ready to see what’s next?</AntdComponents.Typography>
        <div className="mt-5 px-4">
        <CustomButton title='Continue' className='mt-12' isLoading={submitLoading} onClick={() =>handleSubmit()} />
        </div>
      </div>
    </div>
  )
}

export default ThankYou
