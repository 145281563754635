import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../../../utils/ReactIcons'
import DebouncedSearch from '../../../components/DebouncedSearch';
import { STATIC_IMAGES } from '../../../utils/StaticImages';
import UploadModal from '../../../components/UploadModal';
import CardCommon from '../../../components/CardCommon';
import CustomButton from '../../../components/CustomButton';
import OutlineButton from '../../../components/OutlineButton';
import CustomDivider from '../../../components/CustomDivider';
import { AUTH } from '../../../utils/Endpoints';
import CustomToast from '../../../utils/CustomToast';
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice';
import { QueryKeys } from '../../../utils/RTKKeys';
import UploadStatus from './UploadStatus';
import moment from 'moment';
import CommonDelete from '../../../components/CommonDelete';
import CommonRename from '../../../components/CommonRename';
import { RouterKeys } from '../../../Routes/RouterKeys';
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader';
import { fileNameAndUrl } from '../../../utils/Constant';
import HistoryModal from './HistoryModal';
import ShareWithContact from '../../../components/ShareWithContact';
const popoverArr = [
  {
    title: 'Share Folder',
    icon: STATIC_IMAGES.SHARE,
    type: 'share'
  },
  {
    title: 'Delete Folder',
    icon: STATIC_IMAGES.DELETE,
    type: 'delete'
  },
  {
    title: 'View file history',
    icon: STATIC_IMAGES.HISTORY,
    type: 'history'
  },
  {
    title: 'Rename Folder',
    icon: STATIC_IMAGES.EDIT,
    type: 'edit'
  },
]
const Home = () => {
  const [historyModalOpen, setHistoryModalOpen] = React.useState(false)
  const navigate = useNavigate()
  const [uploadModalOpen, setUploadModalOpen] = React.useState(false)
  const [popOverOpenData, setPopOverOpenData] = React.useState({})
  const [selectData, setSelectData] = React.useState({})
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [renameModalOpen, setRenameModalOpen] = React.useState(false)
  const [folderList, setFolderList] = React.useState([])
  const [blobImages, setBlobImages] = React.useState([])
  const [completeStatus, setCompleteStatus] = React.useState(false)
  const [queryData,setQueryData] = React.useState('')
  // share modal states
  const [shareOptionModal, setShareOptionModal] = React.useState(false)
  const [mainOpen, setMainOpen] = React.useState(false)

  // get  listing
  const { data: listData, isLoading } = useDynamicQueryQuery({
    endpoint: AUTH.HOME.FOLDER.GET_FOLDER_LIST,
    params: {},
    key: QueryKeys.FOLDER_LIST
  }, { refetchOnMountOrArgChange: true })

  React.useEffect(() => {
    if (listData?.data) {
      setFolderList(listData?.data)
    }
  }, [listData])
  // const folderList = listData?.data
  // create folder 
  const [createFolderMutation, { isLoading: folderCreateLoading }] = useDynamicMutationMutation();
  // uploading file
  const [triggerDocumentUpload, { data: uploadedImages, isLoading: docUploading, isSuccess: docUploadSuccess, }] = useDynamicMutationMutation();

  // delete folder
  const [deleteMutation, { isLoading: isDeleteLoading }] = useDynamicMutationMutation();
  // rename folder
  const [renameMutation, { isLoading: isRenameLoading }] = useDynamicMutationMutation();

  // threeDot content
  const PopOverContent = ({ data }) => {
    let updatedArr = []
    if (data?.shared_by) {
      updatedArr.push({
        title: 'View file history',
        icon: STATIC_IMAGES.HISTORY,
        type: 'history'
      })
    } else {
      // updatedArr = popoverArr
      let mapArr = popoverArr?.map((ele) => {
        if (ele?.title.includes('Folder') && data['document_name'] && (!data?.shared_by)) {
          return {
            ...ele,
            title: ele.title.replace('Folder', 'File')
          }
        } else {
          return ele
        }
      })
      updatedArr = mapArr

    }
    const openFunction = (e, type) => {
      e.stopPropagation()
      setPopOverOpenData({});
      setSelectData(data)
      switch (type) {
        case 'edit': {
          setRenameModalOpen(true)
        }
          break;
        case 'delete': {
          setDeleteModal(true);
        }
          break;
        case 'share': {
          setShareOptionModal(true)
          setMainOpen(true)
        }
          break;
        case 'history': {
          setHistoryModalOpen(true)
        }
          break;
        default:
          CustomToast('e', "You can't share the folder externally.")
      }
    }
    return (
      <div className="p-3 w-96">
        <div className="flex justify-end">
          <ReactIcons.CloseIcon onClick={(e) => { e.stopPropagation(); setPopOverOpenData({}) }} />
        </div>
        <div className="flex gap-4">
          <AntdComponents.Avatar src={STATIC_IMAGES.FOLDER} size={40} />
          <div className="">
            <AntdComponents.Typography className='titleSmall'>{data?.folder_name}</AntdComponents.Typography>
            <AntdComponents.Typography className='text-description font-normal text-DarkGrey'>{moment(data?.createdAt).format('ddd MM-DD-YYYY h:mm')}</AntdComponents.Typography>
            {
              data?.shared_by &&
              <AntdComponents.Typography className='description'>Shared by {data?.shared_by}</AntdComponents.Typography>
            }
          </div>
        </div>
        <CustomDivider className={'mt-4'} />
        {
          updatedArr?.map((item, index) => {
            return (
              <div key={index} className="flex items-center gap-5 mt-3" role='button' tabIndex={0}
                onClick={(e) => openFunction(e, item?.type)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openFunction(e, item?.type)
                  }
                }}
              >
                <AntdComponents.Avatar src={item?.icon} size={40} />
                <AntdComponents.Typography className='titleSmall font-normal'>{item?.title}</AntdComponents.Typography>
              </div>
            )
          })
        }
      </div>
    )
  }

  // search
  const handleSearch = (query) => {
    setQueryData(query)
    // let filterData = folderList?.filter((item) => item?.folder_name?.toUpperCase()?.includes(query?.toUpperCase()))
    // setFolderList(filterData)
  };

  // delete folder
  const handleDelete = async () => {
    let payload = {}
    if (selectData['folder_name']) {
      payload.folder_id = selectData?._id
    } else {
      payload.document_id = [selectData?._id]
    }

    let requestData = {
      endpoint: selectData['folder_name'] ? AUTH.HOME.FOLDER.FOLDER_DELETE : AUTH.HOME.DOCUMENT.DOCUMENT_DELETE,
      method: 'DELETE',
      body: payload,
      key: QueryKeys.FOLDER_LIST
    };

    try {
      const { data, error } = await deleteMutation(requestData);
      if (data) {
        CustomToast('s', data?.data?.message || 'Folder deleted successfully!');
        setDeleteModal(false)
        setSelectData('')
      } else {
        CustomToast('e', error?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }
  }

  // rename folder 
  const handleFolderRename = async (values, type) => {
    let payload = {
      "folder_name": values?.rename_input,
      "folder_id": selectData?._id,
    };
    if (selectData['folder_name']) {
      payload.folder_name = values?.rename_input
    } else {
      payload.document_name = values?.rename_input
      payload.document_id = selectData?._id
    }

    let requestData = {
      endpoint: selectData['folder_name'] ? AUTH.HOME.FOLDER.FOLDER_UPDATE : AUTH.HOME.DOCUMENT.DOCUMENT_UPDATE,
      method: 'PUT',
      body: payload,
      key: QueryKeys.FOLDER_LIST
    };

    try {
      const { data, error } = await renameMutation(requestData);
      if (data) {
        CustomToast('s', data?.message || 'Folder renamed successfully!');
        setRenameModalOpen(false)
        setSelectData({})
      } else {
        CustomToast('e', error?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }
  }

  return (
    <>
      {
        (!docUploading && !completeStatus) ?
          <div className='content_common'>
            <AntdComponents.Typography className='titleMedium text-center'>Home</AntdComponents.Typography>
            {
              isLoading ?
                <AntdComponents.Row gutter={[20, 20]}>
                  {
                    [1, 2, 3]?.map((_, index) => {
                      return (
                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                          <SkeletonLoader />
                        </AntdComponents.Col>
                      )
                    })
                  }
                </AntdComponents.Row>
                :
                <div className="mt-4 relative">
                    <div className="text-center my">
                      <DebouncedSearch onSearch={handleSearch} delay={500} />
                    </div>
                  <div className="text-center my-10">
                    {
                      folderList?.length === 0 ?
                        <>
                          <div className="">
                            <AntdComponents.Avatar src={STATIC_IMAGES.SECURE} size={110} shape='square' />
                          </div>
                          <div className="text-center mt-12">
                            <CustomButton title={'Upload'} onClick={() => { setPopOverOpenData({}); setUploadModalOpen(true) }} />
                            <OutlineButton title={'View Checklists'} className={'text-Purple bg-White'} classNameDiv='m-auto mt-5 w-full md:w-64' onClick={() => { navigate(RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEYS) }} />
                          </div>
                        </>
                        :
                        <>
                          <AntdComponents.Row gutter={[20, 20]}>
                            {
                              folderList?.filter((item) => (item?.folder_name||item?.document_name)?.toUpperCase()?.includes(queryData?.toUpperCase()))?.map((item, index) => {
                                let { name, url } = fileNameAndUrl(item)
                                const navigateFunc = () => {
                                  if (!item['document_name']) {
                                    navigate(`${RouterKeys.NON_Auth.HOME.FOLDER_DOCUMENTS}?folder_id=${item?._id}&folder_name=${item?.folder_name}`)
                                  }
                                }
                                return (
                                  <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index} className='cursor-pointer' onClick={() => navigateFunc()}>
                                    <CardCommon data={item} image={url} size={50} shape={'square'} name={name} otherContent={moment(item?.createdAt).format('lll')} popOverOpenData={popOverOpenData} setPopOverOpenData={setPopOverOpenData} PopOverContent={PopOverContent}
                                      byDetailPage={item['document_name'] ? false : true} />
                                  </AntdComponents.Col>
                                )
                              })
                            }
                          </AntdComponents.Row>
                          <div className="text-center fixed bottom-0 left-[55%] my-24">
                            <AntdComponents.Avatar src={STATIC_IMAGES.PlUS_UPLOAD} size={80} role='button' onClick={() => { setPopOverOpenData({}); setUploadModalOpen(true) }} />
                          </div>
                        </>
                    }
                  </div>
                </div>

            }
          </div>
          :
          <>
            {
              ((docUploading || docUploadSuccess) && completeStatus) &&
              <UploadStatus folderCreateLoading={folderCreateLoading} docUploading={docUploading} setCompleteStatus={setCompleteStatus} docUploadSuccess={docUploadSuccess} uploadingImages={blobImages} uploadedImages={uploadedImages?.data || []} />
            }
          </>
      }
      {/* Upload modal */}
      {
        uploadModalOpen &&
        <UploadModal createFolderFunc={createFolderMutation} documentAdd={triggerDocumentUpload} uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen} setBlobImages={setBlobImages} setCompleteStatus={setCompleteStatus} isFolder={true} />
      }
      {/* delete Modal */}
      {
        <CommonDelete deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteMessage={`Do you want to delete this ${selectData?.folder_name ? 'Folder' : 'Document'} ${selectData?.folder_name || selectData?.document_name}?`} deleteFunc={handleDelete} loading={isDeleteLoading} />
      }
      {/* folder rename */}
      {
        renameModalOpen &&
        <CommonRename renameModalOpen={renameModalOpen} setRenameModalOpen={setRenameModalOpen} image={STATIC_IMAGES.FOLDER} data={selectData} submitFunction={handleFolderRename} loading={isRenameLoading} type="Folder" />
      }
      {/* history modal */}
      {
        historyModalOpen &&
        <HistoryModal historyModalOpen={historyModalOpen} setHistoryModalOpen={setHistoryModalOpen} selectData={selectData} />
      }
      {/* share modal */}
      {
        shareOptionModal &&
        <ShareWithContact shareOptionModal={shareOptionModal} setShareOptionModal={setShareOptionModal} mainOpen={mainOpen} setMainOpen={setMainOpen}
          selectData={selectData} />
      }
    </>
  )
}

export default Home
