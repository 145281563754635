import { RouterKeys } from "../Routes/RouterKeys"
import { STATIC_IMAGES } from "./StaticImages"

export const fileUrl = process.env.REACT_APP_FILE_URL
export const linkedInClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID
export const baseUrl = process.env.REACT_APP_BASE_URL

// onboarding screen name
export const screenNames = {
    area: 'areasToProtect',
    weather: 'weatherConditions',
    medical: 'medicalVisits',
}
export const subscription_plans = [
    { value: "", label: 'All' },
    { value: "Monthly", label: 'Monthly' },
    { value: "Annual", label: 'Annual' },
]
// accept only image in input file type
export const acceptImageType = 'image/jpg,image/jpeg,image/png,image/gif'

// white space error through
export const whiteSpaceError = (value) => {
    if (value && value.trim().length === 0) {
        return Promise.reject(new Error('Whitespace is not allowed!'));
    }
    return Promise.resolve();
}
// only allow 10 digit number not string
export const onlyTenNumberInputs = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9\b]+$/;
    if (!regex.test(keyValue) || event.target.value.length >= 10) {
        event.preventDefault();
    }
}
// get file and folder name,url 
export const fileNameAndUrl = (item) => {
    var url = ''
    var name = ''
    if (item['folder_name']) {
        url = STATIC_IMAGES.FOLDER
        name = item?.folder_name
    } else {
        name = item?.document_name
        let type = item?.document_type?.split('/')
        if (type?.[0] === 'image' && item?.document_url != '') {
            url = `${fileUrl}${item?.document_url}`
        } else {
            url = STATIC_IMAGES.FILE
        }
    }
    return { url, name }
}
// folder and document type 
export const getType = (data) => {
    let type = data['folder_name'] ? 'folder' : data['document_name'] && 'document'
    return type
}
// capitalize type
export const capitalizeType = (str) => {
    return str.charAt(0).toUpperCase()+str.slice(1)
};
// scrolling with pagination

// checklist arrays
export const checklistArr = [
    {
        title: 'Medical',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Medical`,
        icon: STATIC_IMAGES.TUTORIAL.MEDICAL_KEY
    },
    {
        title: 'Financial',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Financial`,
        icon: STATIC_IMAGES.TUTORIAL.FINANCIAL
    },
    {
        title: 'Home',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Home`,
        icon: STATIC_IMAGES.TUTORIAL.HOUSE
    },
    {
        title: 'Rental',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Rental`,
        icon: STATIC_IMAGES.TUTORIAL.RENTAL
    },
    {
        title: 'Estate Plans',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Estate Plans`,
        icon: STATIC_IMAGES.TUTORIAL.ESTATE
    },
    {
        title: 'Pets',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Pets`,
        icon: STATIC_IMAGES.TUTORIAL.PET
    },
    {
        title: 'Auto',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Auto`,
        icon: STATIC_IMAGES.TUTORIAL.AUTO
    },
    {
        title: 'Valuables',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Valuables`,
        icon: STATIC_IMAGES.TUTORIAL.Valuable
    },
    {
        title: 'Identity',
        link: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}?key=Identity`,
        icon: STATIC_IMAGES.TUTORIAL.kEY_INFO
    },
]
export const MedicalKeyData = {
    title: 'Medical',
    sub_title: 'Prepare for medical decisions',
    description: 'Using this checklist you can ensure you have the proper documents ready to go in the event of a medical emergency',
    pointArr: ['- Do not resuscitate (DNR)', '- Medical power of attorney', '- Current medications', '- Organ donor', '- Medical records & immunization']
}
export const FinancialKeyData = {
    title: 'Financial information',
    sub_title: 'Backup your financial documents',
    description: 'Using this checklist you can ensure you have a backup for key documents when needed',
    pointArr: ['- Bank account information', '- Credit card details', '- Fraud prevention phone number', '- Investment portfolio', '- Income tax documents']
}
export const HomeKeyData = {
    title: 'Home Ownership',
    sub_title: 'Using this checklist you can ensure you have the proper documents to protect your home',
    description: 'Using this checklist you can ensure you have a backup for key documents when needed',
    pointArr: ['- Mortgage loan', '- Renovation photos', '- Property taxes', '- Home insurance', '- Deed of trust']
}
export const RentalKeyData = {
    title: 'Rental',
    sub_title: 'Renter documents',
    description: 'Using this checklist you can ensure you have the proper documents as a renter',
    pointArr: ['- Lease agreements', '- Renter’s insurance', '- Before / after photos', '- Valuables',],
    footer_data: 'Aim to have all of these essential documents uploaded into your Vault to have it in a central secure place. '
}
export const EstateKeyData = {
    title: 'Estate planning',
    sub_title: 'Sharing your estate plans',
    description: 'Using this checklist you can ensure you have the proper documents ready to go as part of estate planning',
    pointArr: ['- Last will and testament', '- Birth certificates', '- Death certificates', '- Funeral arrangements & expenses', '- Trusts', '- Investments and annuities', '- Attorney contact information', '- Life insurance'],
}
export const PetsKeyData = {
    title: 'Pet Information',
    sub_title: 'Store your pet documents',
    description: 'Using this checklist you can ensure you have backup for key documents when needed',
    pointArr: ['- Adoption document', '- Vaccination report', '- Service Animal (SA)', '- Pet passport ', '- Pet insurance', '- Pet photos'],
}
export const AutoKeyData = {
    title: 'Motor vehicle',
    sub_title: 'Prepare for car events',
    description: 'Using this checklist you can ensure you have the proper documents ready to go as part of estate planning',
    pointArr: ['- Buyer’s contract', '- Leasing agreement', '- Car insurance', '- Repairs', '- Accidents', '- Car title'],
}
export const ValuableKeyData = {
    title: 'Valuables',
    sub_title: 'Prove ownership of high value items',
    description: 'Using this checklist you can ensure you have the proper documents ready to go in the event of a medical emergency',
    pointArr: ['- Jewelry receipt', '- Antiques', '- Artwork', '- Household items', '- Assets', '- Photo inventory'],
}
export const KeyInfoKeyData = {
    title: 'Key information',
    sub_title: 'Back up your private information',
    description: 'Using this checklist you can ensure you have the proper documents ready to go in the event of a medical emergency',
    pointArr: ['- Social security card', '- U.S. Visa', '- Driver license', '- Passport ID', '- Family documents'],
}
// checklist arrays end