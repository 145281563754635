import React from 'react'
import * as AntdComponents from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ReactIcons } from '../../../utils/ReactIcons'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { acceptImageType, fileUrl, onlyTenNumberInputs, whiteSpaceError } from '../../../utils/Constant'
import CustomButton from '../../../components/CustomButton'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import { useDynamicMutationMutation } from '../../../redux/service/apiSlice'
import CustomToast from '../../../utils/CustomToast'
import LoaderCircle from '../../../components/Loader'
import { AuthData } from '../../../redux/slices/authSlice'

const ProfileComp = () => {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state?.auth?.auth_data)
  const [profileForm] = AntdComponents.Form.useForm()
  const usedPercentage = (userData?.storage_details?.storage_used / userData?.storage_details?.total_storage) * 100;
  const remainingPercentage = 100 - usedPercentage;
  const [profilePicUrl, setProfilePic] = React.useState('')
  // profile pic upload
  const [triggerProfileUpdate, { isLoading: profilePicLoading }] = useDynamicMutationMutation();
  // detail update
  const [triggerDetailUpdate, { isLoading: detailUpdateLoading }] = useDynamicMutationMutation();

  React.useEffect(() => {
    profileForm.setFieldsValue({
      'name': userData?.user_name,
      'email': userData?.email,
      'phone': userData?.phone_number,
    })
    setProfilePic(userData?.profile_pic)
  }, [userData])
  console.log('profilePicUrl', profilePicUrl);


  const handleFileChange = async (e) => {
    let file = e.target.files?.[0]
    console.log('file', file);
    let formData = new FormData
    formData.append('profile_pic', file)
    let requestData = {
      endpoint: AUTH.PROFILE.USER_PROFILE_PIC,
      method: 'POST',
      body: formData,
      key: QueryKeys.FOLDER_DETAIL
    };

    try {
      const { data, error } = await triggerProfileUpdate(requestData);
      if (data) {
        setProfilePic(data?.data?.[0])
      } else {
        CustomToast('e', error?.data?.message || 'Something went wrong');

      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }
  }

  const handleFinish = async (values) => {
    let payload = {
      user_name: values?.name,
      phone_number: values?.phone,
      // email:values?.email,
      profile_pic: profilePicUrl,
    }
    let requestData = {
      endpoint: AUTH.PROFILE.USER_PROFILE_UPDATE,
      method: 'PUT',
      body: payload,
    };

    try {
      const { data, error } = await triggerDetailUpdate(requestData);
      if (data?.data) {
        let updateObj = {
          user_name: data?.data?.user_name,
          email: data?.data?.email,
          phone_number: data?.data?.phone_number,
          profile_pic: data?.data?.profile_pic,
        }
        dispatch(AuthData(updateObj))
      } else {
        CustomToast('e', error?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }
  }


  return (
    <div className='content_common'>
      <div className="flex gap-5 mt-5">
        {/* left side PROFILE PIC */}
        <AntdComponents.Spin spinning={profilePicLoading} indicator={<LoaderCircle divHeight={'100%'} height='60' width='60' />}>
          <div className="relative">
            <AntdComponents.Avatar size={130} className='bg-GreyLight2' src={profilePicUrl ? `${fileUrl}${profilePicUrl}` : <ReactIcons.profileIcon className='text-GreyLight3 h-32' style={{ fontSize: 70 }} />
            } style={{ cursor: 'pointer' }}>
            </AntdComponents.Avatar>
            <div className="absolute top-1 left-1">
              <input id='profile_pic' type='file' accept={acceptImageType} className='hidden' onChange={(e) => handleFileChange(e)} />
              <label htmlFor='profile_pic' className='cursor-pointer'>
                <AntdComponents.Image src={STATIC_IMAGES.EDIT_PROFILE} height={40} preview={false} />
              </label>
            </div>
          </div>
        </AntdComponents.Spin>
        {/* right side */}
        <div className="flex flex-col mt-3">
          <AntdComponents.Typography className='capitalize titleSmall'>{userData?.user_name}</AntdComponents.Typography>
          <AntdComponents.Progress
            className='w-52'
            percent={usedPercentage.toFixed(2)}
            showInfo={false}
            strokeColor={{
              from: 'var(--darkPurple)',
              to: 'var(--MainPurple)'
            }}
          />
          <div className="flex items-center gap-2">
            <AntdComponents.Avatar src={STATIC_IMAGES.CLOUD} size={40} shape='SQUARE' />
            <AntdComponents.Typography className='capitalize font-normal paragraphSmall'>Storage Remaining: {remainingPercentage.toFixed(2)}%</AntdComponents.Typography>
          </div>
        </div>
      </div>
      {/* form */}
      <div className="mt-8 w-full md:w-1/3">
        <AntdComponents.Form form={profileForm} layout='vertical' onFinish={(values) => handleFinish(values)} requiredMark={false}>
          <AntdComponents.Row gutter={[20, 20]}>
            <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <AntdComponents.Form.Item name="name" label={<AntdComponents.Typography className='paragraph font-semibold'>Name</AntdComponents.Typography>} autoComplete="off" className='mb-0'
                rules={[
                  {
                    required: true,
                    message: 'Please Enter your name!',
                  },
                  {
                    validator: (_, value) => whiteSpaceError(value)
                  },
                ]}>
                <AntdComponents.Input
                  className='input-box'
                  placeholder='Your name'
                  suffix={<AntdComponents.Avatar shape='square' size={30} src={STATIC_IMAGES.EDIT} />}
                  readOnly
                />
              </AntdComponents.Form.Item>
            </AntdComponents.Col>
            <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <AntdComponents.Form.Item name="phone" autoComplete="off" label={<AntdComponents.Typography className='paragraph font-semibold'>Phone Number</AntdComponents.Typography>} className='mb-0'
                rules={[
                  {
                    required: true,
                    message: 'Please Enter your Phone!',
                  },
                  {
                    validator: (_, value) => whiteSpaceError(value)
                  },
                ]}>
                <AntdComponents.Input
                  className='input-box'
                  placeholder='Phone Number'
                  onKeyPress={(e) => onlyTenNumberInputs(e)}
                  suffix={<AntdComponents.Avatar shape='square' size={30} src={STATIC_IMAGES.EDIT} />}
                />
              </AntdComponents.Form.Item>
            </AntdComponents.Col>
            <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <AntdComponents.Form.Item name="email" autoComplete="off" label={<AntdComponents.Typography className='paragraph font-semibold'>Email</AntdComponents.Typography>} className='mb-1'
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email!',
                  },
                  {
                    required: true,
                    message: 'Please Enter your email!',
                  },
                ]}>
                <AntdComponents.Input
                  className='input-box'
                  placeholder='name@domain.com'
                  readOnly
                  suffix={<AntdComponents.Avatar shape='square' size={30} src={STATIC_IMAGES.EDIT} />}
                />
              </AntdComponents.Form.Item>
            </AntdComponents.Col>
          </AntdComponents.Row>
          <div className="my-8">
            <CustomButton title={'Update'} isLoading={detailUpdateLoading} htmlType='submit' />
          </div>
        </AntdComponents.Form>
      </div>

    </div>
  )
}

export default ProfileComp
