import { BsThreeDots } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { FaFacebook, FaUser } from "react-icons/fa";
import { IoChevronBackCircle, IoCloseCircle, IoNotificationsOutline, IoSearchOutline } from "react-icons/io5";
import {  RiLogoutCircleRLine } from "react-icons/ri";
import { BiShareAlt } from "react-icons/bi";


export const ReactIcons = {
    BackIcon: ({ style, className, onClick }) => <IoChevronBackCircle style={{ ...style, fontSize: 30, }} className={`${className} cursor-pointer text-DarkPurple`} onClick={onClick} />,
    SettingIcon: ({ style, className, onClick }) => <CiSettings style={style} className={`${className} cursor-pointer`} onClick={onClick} />,
    NotificationIcon: ({ style, className, onClick }) => <IoNotificationsOutline style={style} className={`${className} cursor-pointer`} onClick={onClick} />,
    SearchIcon: ({ style, className, onClick }) => <IoSearchOutline style={{ ...style, fontSize: 20, }} className={`${className} cursor-pointer`} onClick={onClick} />,
    CloseIcon: ({ style, className, onClick }) => <IoCloseCircle style={{ ...style, fontSize: 30, }} className={`text-DarkPurple cursor-pointer ${className}`} onClick={onClick} />,
    ThreeDotIcon: ({ style, className, onClick }) => <BsThreeDots style={{ ...style, fontSize: 30, }} className={`text-Black cursor-pointer ${className}`} onClick={onClick} />,
    LogOutIcon: ({ style, className, onClick }) => <RiLogoutCircleRLine style={{ ...style, fontSize: 28, }} className={`text-Black cursor-pointer ${className}`} onClick={onClick} />,
    FbIcon: ({ style, className, onClick }) => <FaFacebook color="#1877F2" style={{ ...style,fontSize: 60, }} className={`${className} cursor-pointer`} onClick={onClick} />,
    shareIcon: ({ style, className, onClick }) => <BiShareAlt  style={{ ...style,fontSize: 30, }} className={`${className} cursor-pointer`} onClick={onClick} />,
    profileIcon: ({ style, className, onClick }) => <FaUser  style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
}