import { createSlice } from "@reduxjs/toolkit"
import { screenNames } from "../../utils/Constant"

const initialState = {
    onboarding_screens: {
        area_protect: [],
        weather: [],
        medical: []
    }
}

export const onboardingSlice = createSlice({
    name: 'onboarding_screen',
    initialState,
    reducers: {
        StoreOnboardingData: (state, action) => {
            let data = action.payload
            console.log("data?.screen_name",data?.screen_name,screenNames.area);
            if (data?.screen_name == screenNames.area) {
                state.onboarding_screens.area_protect = data.screen_values
            } else if (data?.screen_name == screenNames.weather) {
                state.onboarding_screens.weather = data.screen_values
            } else if (data?.screen_name == screenNames.medical) {
                state.onboarding_screens.medical = data.screen_values
            }
        },
        RemoveOnboardings: (state, action) => {
            return initialState
        }
    }
})

export const { StoreOnboardingData, RemoveOnboardings } = onboardingSlice.actions
export default onboardingSlice.reducer