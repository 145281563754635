import React from 'react'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../redux/service/apiSlice'
import { AUTH } from '../utils/Endpoints'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { QueryKeys } from '../utils/RTKKeys'
import { ReactIcons } from '../utils/ReactIcons'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../utils/StaticImages'
import { fileUrl } from '../utils/Constant'
import CommonDelete from './CommonDelete'
import CustomToast from '../utils/CustomToast'
import CommonShare from './CommonShare'
import CommonWarning from './CommonWarning'

const CommonDocView = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const id = searchParam.get('id')
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [previewData, setPreviewData] = React.useState({
        url: '',
        fileType: ''
    });
    const [warningModal, setWarningModal] = React.useState(false)
    const [shareModalOpen, setShareModalOpen] = React.useState(false)
    // delete folder
    const [deleteMutation, { isLoading: isDeleteLoading }] = useDynamicMutationMutation();
    // documents detail
    const { data: documentData } = useDynamicQueryQuery({
        endpoint: AUTH.HOME.DOCUMENT.GET_DOCUMENT_DETAIL,
        params: { document_id: id },
        key: QueryKeys.DOCUMENT_DETAIL,
    },
        { skip: !id, refetchOnMountOrArgChange: true }
    )
    const fileData = documentData?.data
    console.log('fileData', fileData?.document_url);

    // document data url handling base on its type
    React.useEffect(() => {
        if (fileData?.document_url) {
            let url = `${fileUrl}${fileData?.document_url}`
            const fileType = fileData?.document_type || fileData?.document_url?.split('.').pop().toLowerCase();
            console.log('fileType', fileType);
            if (fileType === 'application/pdf' || fileType?.startsWith('image')) {
                setPreviewData(
                    {
                        url: url,
                        fileType: fileType
                    }
                );
            } else {
                window.open(url, '_blank');
            }
        }
    }, [documentData])

    // delete file
    const handleDelete = async () => {
        let payload = {
            document_id: [fileData?._id],
        };

        let requestData = {
            endpoint: AUTH.HOME.DOCUMENT.DOCUMENT_DELETE,
            method: 'DELETE',
            body: payload,
            key: QueryKeys.FOLDER_DETAIL
        };

        try {
            const { data, error } = await deleteMutation(requestData);
            if (data) {
                CustomToast('s', data?.data?.message || 'File deleted successfully!');
                setDeleteModal(false)
                navigate(-1)
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    const openShareModal = () => {
        setWarningModal(false)
        setShareModalOpen(true)
    }
    return (
        <>
            <AntdComponents.Row className='mt-3'>
                <AntdComponents.Col xs={24} sm={24} md={22} lg={12} xl={12}>
                    <div className='bg-GreyLight3 rounded-10 p-4'>
                        {
                            fileData?.shared_with?.length === 0 ?
                                <div className="flex items-center justify-between">
                                    <ReactIcons.BackIcon onClick={() => navigate(-1)} />
                                    <AntdComponents.Typography className="paragraph text_truncate md:max-w-200 max-w-66">
                                        {fileData?.document_name}
                                    </AntdComponents.Typography>
                                    <div className="flex items-center gap-3">
                                        <ReactIcons.shareIcon className={'text-Purple'} onClick={() => setWarningModal(true)} />
                                        <AntdComponents.Avatar src={STATIC_IMAGES.DELETE} shape='square' size={30} role='button' onClick={() => { setDeleteModal(true) }} />
                                    </div>
                                </div>
                                :
                                <div className=''>
                                    <ReactIcons.BackIcon onClick={() => navigate(-1)} />
                                    <AntdComponents.Typography className="titleSmall text-center text_truncate w-full">
                                        {fileData?.document_name}
                                    </AntdComponents.Typography>
                                </div>
                        }
                        <div className="mt-4 p-10">
                            {
                                previewData?.fileType === 'application/pdf' ?
                                    <embed src={previewData?.url} type="application/pdf" width="100%" height="600px" />
                                    :
                                    <div className="bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${previewData?.url})`, height: '60vh' }}></div>
                            }
                        </div>
                    </div>
                </AntdComponents.Col>
            </AntdComponents.Row>
            {
                deleteModal &&
                <CommonDelete deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteMessage={`Do you want to delete this file ${fileData?.document_name}?`} deleteFunc={handleDelete} loading={isDeleteLoading} />
            }
            {
                warningModal &&
                <CommonWarning warningModal={warningModal} setWarningModal={setWarningModal} handleClick={openShareModal} />
            }
            {
                shareModalOpen &&
                <CommonShare shareModalOpen={shareModalOpen} setShareModalOpen={setShareModalOpen} shareUrl={previewData?.url} />
            }
        </>
    )
}

export default CommonDocView
