import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../utils/ReactIcons'
import { STATIC_IMAGES } from '../utils/StaticImages'


const DeleteSuccess = ({ deleteSuccessModalOpen, setDeleteSuccessModalOpen }) => {
    return (
        <div>
            <AntdComponents.Modal centered open={deleteSuccessModalOpen} onCancel={() => setDeleteSuccessModalOpen(false)} footer={false} width={550} closeIcon={<ReactIcons.CloseIcon />}>
                <div className="text-center py-5">
                    <AntdComponents.Avatar src={STATIC_IMAGES.DELETE_COMPLETE} size={110} shape='square' />
                    <AntdComponents.Typography className='titleMedium mt-3'>File Deleted</AntdComponents.Typography>
                    <AntdComponents.Typography className='paragraph mt-3'>File is Permanently deleted from trash.</AntdComponents.Typography>
                </div>
            </AntdComponents.Modal>
        </div>
    )
}

export default DeleteSuccess
