import React from 'react'
import * as AntdComponents from 'antd'

const NoData = () => {
  return (
    <div>
      <AntdComponents.Typography className='text-center titleLarge'>No Data Found!</AntdComponents.Typography>
    </div>
  )
}

export default NoData
