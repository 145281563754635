// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD50CWUwbxYyL0SPZmcDCxSJzUD7BnRt7o",
  authDomain: "vaultpd-9bb43.firebaseapp.com",
  projectId: "vaultpd-9bb43",
  storageBucket: "vaultpd-9bb43.firebasestorage.app",
  messagingSenderId: "913985562733",
  appId: "1:913985562733:web:e649ce6cffb69cab1ef764",
  measurementId: "G-9CSS2YCBQ1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app)