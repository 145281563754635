import React from 'react'
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
const LoaderConfetti = ({stopState=true}) => {
    // const { width, height } = useWindowSize()
    let random = Math.random()
    console.log("random",random);
    
    return (
        <Confetti
        width={150} // Confetti container width
        height={120} // Confetti container height
        numberOfPieces={400} // High number of particles for dense explosion
        gravity={-0.03} // No gravity to simulate initial explosion
        recycle={stopState} // Confetti runs if stopState is true
        initialVelocityX={1 * 10 - 5} // Random spread horizontally
        initialVelocityY={0.03 * 10 - 5} // Random spread vertically
        colors={['#FFD700', '#FF4500', '#1E90FF', '#FFFFFF']} // Spark-like colors
        drawShape={(ctx) => {
            ctx.beginPath();
            ctx.arc(0, 0, 2, 0, 5 * Math.PI); // Tiny circular particles
            ctx.fill();
        }} 
    />
    )
}

export default LoaderConfetti
