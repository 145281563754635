import React from 'react'
import { Outlet } from 'react-router-dom'

const NonAuthLayout = () => {
    return (
            <div className="bg-gray-100 relative h-screen w-full">
                <div className="absolute right-0 top-0 h-[300px] w-full max-w-sm rounded-bl-full bg-BlueTransparent blur-3xl"></div>
                <div className="absolute bottom-0 left-0 h-[300px] w-full max-w-sm rounded-bl-none rounded-tr-full bg-OrangeTransparent blur-3xl"></div>

                {/* Main Content */}
                <div className="relative flex min-h-full items-center justify-center">
                    <Outlet />
                </div>
            </div>
    )
}

export default NonAuthLayout
