import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'
import CardCommon from '../../../components/CardCommon'
import moment from 'moment'
import CustomDivider from '../../../components/CustomDivider'
import CustomToast from '../../../utils/CustomToast'
import { ReactIcons } from '../../../utils/ReactIcons'
import { fileUrl } from '../../../utils/Constant'
import CommonDelete from '../../../components/CommonDelete'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom'
import useIntersectionObserver from '../../../Hook/useIntersectionObserver'

const popoverArr = [
    {
        title: 'View upload',
        icon: STATIC_IMAGES.FORM.EYE_ONN,
        type: 'view'
    },
    {
        title: 'Delete Notification',
        icon: STATIC_IMAGES.DELETE,
        type: 'delete'
    },
]
const Notification = () => {
    const navigate = useNavigate()
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [popOverOpenData, setPopOverOpenData] = React.useState({})
    const [listData, setListData] = React.useState([])

    // pagination and endless scrolling states
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true)
    const loadMoreRef = React.useRef(null);

    // get  listing
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.NOTIFICATION.NOTIFICATION_LIST,
        params: { page: page, limit: 12 },
        key: QueryKeys.NOTIFICATION_LIST
    }, { refetchOnMountOrArgChange: true })
    // accept decline notification
    const [triggerAcceptDecline, { isLoading: btnLoading }] = useDynamicMutationMutation();
    // delete notification
    const [triggerDeleteMutation, { isLoading: deleteLoading }] = useDynamicMutationMutation();


    // data set with scrolling
    React.useEffect(() => {
        let mainData = data?.data?.result
        if (mainData?.length === 0) {
            setHasMore(false);
        } else {
            setListData((prevData) => {
                if (prevData?.length > 0) {
                    return [...prevData, ...mainData]
                } else {
                    return mainData
                }
            });
        }
    }, [data])
    // endless scrolling
    useIntersectionObserver({
        targetRef: loadMoreRef,
        hasMore,
        isLoading,
        setPage,
    })


    // threeDot content
    const PopOverContent = ({ data }) => {
        const openFunction = (e, type) => {
            e.stopPropagation()
            setPopOverOpenData({});
            switch (type) {
                case 'view': {
                    if (data?.shared_data?.file_type === 'Folder') {
                        navigate(`${RouterKeys.NON_Auth.HOME.FOLDER_DOCUMENTS}?folder_id=${data?.ref_id}`)
                    } else {
                        navigate(`${RouterKeys.NON_Auth.HOME.DOCUMENTS_VIEW}?id=${data?.ref_id}`)
                    }
                }
                    break;
                case 'delete': {
                    deleteNotification(data, 'single')
                }
                    break;
                default:
                    CustomToast('e', "You can't share the folder externally.")
            }
        }
        return (
            <div className="p-3 w-96">
                <div className="flex justify-end">
                    <ReactIcons.CloseIcon onClick={(e) => { e.stopPropagation(); setPopOverOpenData({}) }} />
                </div>
                <div className="flex gap-4">
                    <AntdComponents.Avatar src={STATIC_IMAGES.FOLDER} size={40} />
                    <div className="">
                        <AntdComponents.Typography className='titleSmall text_truncate max-w-66 md:max-w-120 lg:max-w-200'>{data?.message}</AntdComponents.Typography>
                        <AntdComponents.Typography className='text-description font-normal text-DarkGrey'>{moment(data?.createdAt).format('ddd MM-DD-YYYY h:mm')}</AntdComponents.Typography>
                    </div>
                </div>
                <CustomDivider className={'mt-4'} />
                {
                    popoverArr?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center gap-5 mt-3" role='button' tabIndex={0}
                                onClick={(e) => openFunction(e, item?.type)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        openFunction(e, item?.type)
                                    }
                                }}
                            >
                                <AntdComponents.Avatar src={item?.icon} size={40} />
                                <AntdComponents.Typography className='titleSmall font-normal'>{item?.title}</AntdComponents.Typography>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    // accept decline
    const acceptDecline = async (item, type) => {
        let payload = {
            "is_accepted": type,
            "notification_id": item?._id
        }
        let requestData = {
            endpoint: AUTH.NOTIFICATION.NOTIFICATION_ACCEPT_REJECT,
            method: 'POST',
            body: payload,
            key: QueryKeys.NOTIFICATION_LIST
        };

        try {
            const { data, error } = await triggerAcceptDecline(requestData);
            if (data?.data) {
                CustomToast('s', data?.message);
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }

    // delete notifications
    const deleteNotification = async (item = {}, type = 'multiple') => {
        let payloadSingleDelete = {
            notification_id: [item?._id]
        }
        let payloadAllDelete = {
            type: 'simple'
        }
        let requestData = {
            endpoint: type === 'single' ? AUTH.NOTIFICATION.NOTIFICATION_DELETE : AUTH.NOTIFICATION.NOTIFICATION_DELETE_ALL,
            method: 'DELETE',
            body: type === 'single' ? payloadSingleDelete : payloadAllDelete,
            // key: QueryKeys.NOTIFICATION_LIST
        };

        try {
            const { data, error } = await triggerDeleteMutation(requestData);
            if (data?.data) {
                CustomToast('s', data?.message);
                setDeleteModal(false)
                let updateArr = listData?.filter((ele) => item?._id !== ele?._id)
                setListData(updateArr)
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    return (
        <>
            <div className='content_common'>
                <div className={`flex items-center ${listData?.length == 0 ? 'justify-center' : 'justify-between'} `}>
                    <p></p>
                    <AntdComponents.Typography className='titleMedium'>Notification</AntdComponents.Typography>
                    {
                        listData?.length > 0 &&
                        <AntdComponents.Tooltip title='Clear All'>
                            <AntdComponents.Avatar src={STATIC_IMAGES.CLEAR_ALL} shape='square' size={60} role='button' onClick={() => setDeleteModal(true)} />
                        </AntdComponents.Tooltip>
                    }
                </div>
                {
                    isLoading ?
                        <AntdComponents.Row gutter={[20, 20]}>
                            {
                                [1, 2, 3]?.map((_, index) => {
                                    return (
                                        <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
                                            <SkeletonLoader />
                                        </AntdComponents.Col>
                                    )
                                })
                            }
                        </AntdComponents.Row>
                        :
                        <>
                            {
                                listData?.length === 0 ?
                                    <div className='text-center mt-5'>
                                        <AntdComponents.Avatar src={STATIC_IMAGES.NOTIFICATION_REVERSE} size={110} shape='square' />
                                        <AntdComponents.Typography className='titleSmall'>No Notification Found</AntdComponents.Typography>
                                    </div>
                                    :
                                    <AntdComponents.Row gutter={[20, 20]} className='mt-5'>
                                        {
                                            listData?.map((item, index) => {
                                                let image = item?.profile_pic ? `${fileUrl}${item?.profile_pic}` : STATIC_IMAGES?.NOTIFICATION
                                                return (
                                                    <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24} key={index} className='cursor-pointer'>
                                                        <CardCommon data={item} image={image} size={60} shape={item?.profile_pic ? 'circle' : 'square'} name={item?.message} otherContent={moment(item?.createdAt).format('lll')} popOverOpenData={popOverOpenData} setPopOverOpenData={setPopOverOpenData} PopOverContent={PopOverContent} byDetailPage={true} truncate={false} acceptDecline={acceptDecline} btnLoading={btnLoading} />
                                                    </AntdComponents.Col>
                                                )
                                            })
                                        }
                                    </AntdComponents.Row>
                            }
                        </>
                }
                {
                    deleteModal &&
                    <CommonDelete deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteMessage={'Do you want to clear all the notifications?'} deleteFunc={deleteNotification} loading={deleteLoading} />
                }

            </div>
            {/* scrolling */}
            {hasMore && <div ref={loadMoreRef} className=''></div>}
        </>
    )
}

export default Notification
