import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from './StaticImages'
const SuspenseLoader = () => {
  return (
    <div className="bg-gray-100 relative h-screen w-full">
      <div className="absolute right-0 top-0 h-[200px] w-full max-w-sm rounded-bl-full bg-BlueTransparent blur-3xl"></div>
      <div className="absolute bottom-0 left-0 h-[250px] w-full max-w-sm rounded-bl-none rounded-tr-full bg-OrangeTransparent blur-3xl"></div>

      {/* Main Content */}
      <div className="relative flex h-full items-center justify-center">
        <AntdComponents.Image src={STATIC_IMAGES.LOGO} preview={false} height={80} />
      </div>
    </div>

  )
}

export default SuspenseLoader
