import React from 'react'
import * as AntdComponents from 'antd'
import CardCommon from '../../../components/CardCommon'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'
import CustomToast from '../../../utils/CustomToast'
import { ReactIcons } from '../../../utils/ReactIcons'
import moment from 'moment'
import CustomDivider from '../../../components/CustomDivider'
import { fileNameAndUrl } from '../../../utils/Constant'
import RestoreSuccess from '../../../components/RestoreSuccess'
import DeleteSuccess from '../../../components/DeleteSuccess'
import useIntersectionObserver from '../../../Hook/useIntersectionObserver'

const popoverArr = [
    {
        title: 'Add back to my vault',
        icon: STATIC_IMAGES.ADD,
        type: 'restore_one'
    },
    {
        title: 'Delete Permanently',
        icon: STATIC_IMAGES.MINUS,
        type: 'delete_one'
    },
]
const Trash = () => {
    const [deleteSuccessModalOpen, setDeleteSuccessModalOpen] = React.useState(false)
    const [restoreModalOpen, setRestoreModalOpen] = React.useState(false)
    const [popOverOpenData, setPopOverOpenData] = React.useState({})
    const [listData, setListData] = React.useState([])

    // pagination and endless scrolling states
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true)
    const loadMoreRef = React.useRef(null);
    console.log('page', page);

    // restore file
    const [restoreMutation] = useDynamicMutationMutation();

    // trash listing
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.TRASH.TRASH_LIST,
        params: {},
        key: QueryKeys.TRASH_LIST
    },
        { refetchOnMountOrArgChange: true }
    )
    // const listData = data?.data


    // data set with scrolling
    React.useEffect(() => {
        let mainData = data?.data
        if (mainData?.length === 0) {
            setHasMore(false);
        } else {
            setListData((prevData) => {
                if (prevData?.length > 0) {
                    return [...prevData, ...mainData]
                } else {
                    return mainData
                }
            });
        }
    }, [data])
    // endless scrolling
    useIntersectionObserver({
        targetRef: loadMoreRef,
        hasMore,
        isLoading,
        setPage,
    })
    // restore file function
    const restoreFiles = async (data = {}, type) => {
        let payload = {
            type: data['folder_name'] ? 'folder' : data['document_name'] && 'document',
            file_id: data?._id,
        };

        let requestData = {
            endpoint: type === 'restore_one' ? AUTH.TRASH.RESTORE_ONE : AUTH.TRASH.RESTORE_ALL,
            method: 'PUT',
            body: type === 'restore_one' ? payload : {},
            key: QueryKeys.TRASH_LIST
        };

        try {
            const { data, error } = await restoreMutation(requestData);
            if (data) {
                setRestoreModalOpen(true)
                setTimeout(() => {
                    setRestoreModalOpen(false)
                }, 2500)
            }
            if (error) {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    // delete file function
    const deleteFiles = async (data = {}, type) => {
        let payload = {
            type: data['folder_name'] ? 'folder' : data['document_name'] && 'document',
            file_id: data?._id,
        };

        let requestData = {
            endpoint: type === 'delete_one' ? AUTH.TRASH.DELETE_ONE : AUTH.TRASH.DELETE_ALL,
            method: 'Delete',
            body: type === 'delete_one' ? payload : {},
            key: QueryKeys.TRASH_LIST
        };

        try {
            const { data, error } = await restoreMutation(requestData);
            if (data) {
                let updateArr = listData?.filter((ele) => data?._id !== ele?._id)
                setListData(updateArr)
                setDeleteSuccessModalOpen(true)
                setTimeout(() => {
                    setDeleteSuccessModalOpen(false)
                }, 2500)
            }
            if (error) {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }

    // threeDot content
    const PopOverContent = ({ data }) => {
        const openFunction = (e, type) => {
            e.stopPropagation()
            setPopOverOpenData({});
            switch (type) {
                case 'delete_one': {
                    deleteFiles(data, type)
                }
                    break;
                case 'restore_one': {
                    restoreFiles(data, type)
                }
                    break;
                default:
                    CustomToast('e', "Something wrong")
            }
        }
        return (
            <div className="p-3 w-96">
                <div className="flex justify-end">
                    <ReactIcons.CloseIcon onClick={(e) => { e.stopPropagation(); setPopOverOpenData({}) }} />
                </div>
                <div className="flex gap-4">
                    <AntdComponents.Avatar src={STATIC_IMAGES.FOLDER} size={40} />
                    <div className="">
                        <AntdComponents.Typography className='titleSmall'>{data?.folder_name}</AntdComponents.Typography>
                        <AntdComponents.Typography className='text-description font-normal text-DarkGrey'>{moment(data?.createdAt).format('lll')}</AntdComponents.Typography>
                    </div>
                </div>
                <CustomDivider className={'mt-4'} />
                {
                    popoverArr?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center gap-5 mt-3" role='button' tabIndex={0}
                                onClick={(e) => openFunction(e, item?.type)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        openFunction(e, item?.type)
                                    }
                                }}
                            >
                                <AntdComponents.Avatar src={item?.icon} size={40} />
                                <AntdComponents.Typography className='titleSmall font-normal'>{item?.title}</AntdComponents.Typography>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            <div className='content_common'>

                {
                    isLoading ?
                        <AntdComponents.Row gutter={[20, 20]}>
                            {
                                [1, 2, 3]?.map((_, index) => {
                                    return (
                                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                                            <SkeletonLoader />
                                        </AntdComponents.Col>
                                    )
                                })
                            }
                        </AntdComponents.Row>
                        :
                        <>
                            {
                                listData?.length === 0 ?
                                    <div className='text-center mt-5'>
                                        <AntdComponents.Avatar src={STATIC_IMAGES.DELETE} size={110} shape='square' />
                                        <AntdComponents.Typography className='titleMedium'>Empty Trash</AntdComponents.Typography>
                                        <AntdComponents.Typography className='paragraph mt-3'>There are no files in your trash.</AntdComponents.Typography>
                                    </div>
                                    :
                                    <div className="text-center my-10">
                                        <AntdComponents.Typography className='titleMedium text-center'>Trash</AntdComponents.Typography>
                                        <div className="text-center mt-2">
                                            <AntdComponents.Typography className='paragraph'>These files are deleted from your vault.They are permanently<br /> deleted after 30 days in your trash.</AntdComponents.Typography>
                                            <AntdComponents.Typography className='paragraph font-bold text-Red mt-3 cursor-pointer' onClick={() => deleteFiles({}, 'delete_all')}>Delete all files now</AntdComponents.Typography>
                                            <AntdComponents.Typography className='paragraph font-bold mt-3 text underline cursor-pointer' onClick={() => restoreFiles({}, 'restore_all')}>Recover all deleted files</AntdComponents.Typography>
                                        </div>
                                        <AntdComponents.Row gutter={[20, 20]} className='mt-5'>
                                            {
                                                listData?.map((item, index) => {
                                                    let { name, url } = fileNameAndUrl(item)
                                                    return (
                                                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index} className='cursor-pointer'>
                                                            <CardCommon data={item} image={url} size={50} shape={'square'} name={name} popOverOpenData={popOverOpenData} setPopOverOpenData={setPopOverOpenData} PopOverContent={PopOverContent} byDetailPage={true} />
                                                        </AntdComponents.Col>
                                                    )
                                                })
                                            }
                                        </AntdComponents.Row>
                                    </div>
                            }
                        </>
                }
                {/* scrolling */}
                {hasMore && <div ref={loadMoreRef} className=''></div>}
            </div>
            {
                restoreModalOpen &&
                <RestoreSuccess restoreModalOpen={restoreModalOpen} setRestoreModalOpen={setRestoreModalOpen} />
            }
            {
                deleteSuccessModalOpen &&
                <DeleteSuccess deleteSuccessModalOpen={deleteSuccessModalOpen} setDeleteSuccessModalOpen={setDeleteSuccessModalOpen} />
            }
        </>
    )
}

export default Trash
