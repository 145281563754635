import React from 'react'
import * as AntdComponents from 'antd'

const CustomButton = ({isLoading=false,htmlType='button',title,className,onClick=()=>{}}) => {
  return (
    <AntdComponents.Button className={`gradient-button common-button ${className}`} loading={isLoading} htmlType={htmlType} onClick={onClick}>{title}</AntdComponents.Button>
  )
}

export default CustomButton
