import React from 'react';
import { Divider } from 'antd';

const CustomDivider = ({ type = 'horizontal', className }) => {
  return (
    <Divider
      type={type}
      className={`my-[2px] h-[2px] bg-GreyLight2 ${className}`}
    />
  );
};


export default CustomDivider;