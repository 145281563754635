import React from 'react'
import * as AntdComponents from "antd"
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom'
import { screenNames } from '../../../utils/Constant'
import { QueryKeys } from '../../../utils/RTKKeys'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import { useCustomDynamicQuery } from '../../../redux/hook/useApi'
import { AUTH } from '../../../utils/Endpoints'
import { StoreOnboardingData } from '../../../redux/slices/onboardingSlice'
import CustomButton from '../../../components/CustomButton'


const Weather = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onboardingAllData = useSelector((state)=>state?.onboarding_screen?.onboarding_screens)
  console.log("onboardingAllData",onboardingAllData);
  const { data, isLoading } = useCustomDynamicQuery({
    endpoint: AUTH.ONBOARDING.ONBOARDING_SCREEN_DATA,
    params: { screen_name: screenNames?.weather },
    key: QueryKeys?.WEATHER_SCREEN,
  })
  let screenData = data?.data?.result
  const [selected, setSelected] = React.useState([])

  const setValues = (value) => {
    setSelected([...selected, value])
  }
  //set screen values in redux
  const handleSubmit = async () => {
    let payload = {
      screen_name: screenNames?.weather,
      screen_values: selected
    }
    dispatch(StoreOnboardingData(payload))
    navigate(`/${RouterKeys.NON_Auth.ONBOARDING.HOSPITAL}`)
  }
  return (
    <div>
      <AntdComponents.Typography className='titleMedium font-bold'>Any weather conditions to<br /> prepare for?</AntdComponents.Typography>
      {
        isLoading ? <Loader />
          :
          <>
            <AntdComponents.Typography className='paragraph mt-3'>You can pick more than one.</AntdComponents.Typography>
            <div className="px-5 md:px-12 mt-7">
              <AntdComponents.Row gutter={[16, 16]}>
                {
                  screenData?.map((item, index) => {
                    return (
                      <AntdComponents.Col xs={12} sm={12} md={8} lg={8} xl={8} key={index}>
                        <AntdComponents.Button type='ghost' className={`w-full h-14 bg-GreyLight p-3 border-2 rounded-5 ${selected.includes(item?._id) ? 'border-Purple' : 'border-GreyLight2'} text-para `} onClick={() => setValues(item?._id)}>{item?.screen_value}</AntdComponents.Button>
                      </AntdComponents.Col>
                    )
                  })
                }
              </AntdComponents.Row>
              <CustomButton title='Continue' className='mt-14' onClick={() =>handleSubmit()} />
            </div>
          </>
      }
    </div>
  )
}

export default Weather
