import React from 'react'
import OutlineButton from '../components/OutlineButton'
import { useNavigate } from 'react-router-dom'
import { RouterKeys } from '../Routes/RouterKeys'
import CommonHeader from '../screen/Auth/CommonHeader'
import CustomButton from '../components/CustomButton'
const RegisterAndLogin = () => {
  const navigate = useNavigate()
  return (
    <div className='auth-main-div'>
      <CommonHeader />
      <div className="mt-12 flex flex-col md:flex-row items-center justify-center gap-3">
        <CustomButton title='Register' onClick={() => navigate(`/${RouterKeys.Auth.REGISTER}`)} />
        <OutlineButton title={'Login'} className={'text-Purple bg-White'} classNameDiv='w-full md:w-64' onClick={() => { navigate(`/${RouterKeys.Auth.LOGIN}`) }} />
      </div>
    </div>
  )
}

export default RegisterAndLogin
