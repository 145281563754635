import React from 'react'
import * as AntdComponents from 'antd'
import CustomButton from './CustomButton'
import { ReactIcons } from '../utils/ReactIcons'
import { STATIC_IMAGES } from '../utils/StaticImages'
import { fileUrl } from '../utils/Constant'

const CommonRename = ({ renameModalOpen, setRenameModalOpen, data, submitFunction, type, loading, inputPadding = 'p-2' }) => {
    console.log("data!", data, type);
    const [renameForm] = AntdComponents.Form.useForm()
    const [image, setImage] = React.useState(STATIC_IMAGES.FOLDER)
    React.useEffect(() => {
        var url = ''
        if (type === 'File') {
            let type = data?.document_type.split('/')
            if (type?.[0] === 'image' && data?.document_url != '') {
                url = `${fileUrl}${data?.document_url}`
            } else {
                url = STATIC_IMAGES.FILE
            }
            renameForm.setFieldsValue({
                'rename_input': data?.document_name
            })
        } else {
            url = STATIC_IMAGES.FOLDER
            renameForm.setFieldsValue({
                'rename_input': data?.folder_name||data?.document_name
            })
        }
        setImage(url)
    }, [])
    return (
        <AntdComponents.Modal centered open={renameModalOpen} onCancel={() => setRenameModalOpen(false)} footer={false} width={400} closeIcon={<ReactIcons.CloseIcon />}>
            <AntdComponents.Form form={renameForm} onFinish={(values) => submitFunction(values, type)} layout='vertical'>
                <AntdComponents.Form.Item name={'rename_input'} label={<AntdComponents.Typography className='text-description font-semibold text-center'>Rename {type} </AntdComponents.Typography>} rules={[
                    {
                        required: true,
                        message: `${type} name is required!`
                    }
                ]}>
                    <AntdComponents.Input className={`border-0 bg-White shadow-homeCardShadow rounded-10 mb-2 ${inputPadding}`} prefix={<AntdComponents.Avatar src={image} size={50} />} />

                </AntdComponents.Form.Item>

                <div className="text-center mt-10">
                    <CustomButton isLoading={loading} title={'Done'} htmlType='submit' />
                </div>
            </AntdComponents.Form>
        </AntdComponents.Modal>
    )
}

export default CommonRename
