import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../utils/ReactIcons'
// import { FacebookShareButton, FacebookIcon, LinkedinShareButton, WhatsappShareButton, WhatsappIcon, XIcon, TwitterShareButton, TelegramIcon, TelegramShareButton, LinkedinIcon } from 'react-share'
import * as ShareComponents from "react-share"
const CommonShare = ({ shareModalOpen, setShareModalOpen, shareUrl = 'http://github.com',setShareOptionModal }) => {
    return (
        <div>
            <AntdComponents.Modal centered open={shareModalOpen} onCancel={() =>{setShareModalOpen(false);setShareOptionModal&&setShareOptionModal(false)}} footer={false} width={500} closeIcon={<ReactIcons.CloseIcon />}>
                <AntdComponents.Typography className='titleMedium text-center'>Share your file</AntdComponents.Typography>
                <div className="flex items-center gap-3 justify-center text-center my-7">
                    <ShareComponents.FacebookShareButton url={shareUrl}><ShareComponents.FacebookIcon size={40} round /></ShareComponents.FacebookShareButton>
                    <ShareComponents.LinkedinShareButton url={shareUrl}><ShareComponents.LinkedinIcon size={40} round /></ShareComponents.LinkedinShareButton>
                    <ShareComponents.WhatsappShareButton url={shareUrl}><ShareComponents.WhatsappIcon size={40} round /></ShareComponents.WhatsappShareButton>
                    <ShareComponents.TelegramShareButton url={shareUrl}><ShareComponents.TelegramIcon size={40} round /></ShareComponents.TelegramShareButton>
                    <ShareComponents.TwitterShareButton url={shareUrl}><ShareComponents.XIcon size={40} round /></ShareComponents.TwitterShareButton>
                </div>
            </AntdComponents.Modal>
        </div>
    )
}

export default CommonShare
