import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../utils/ReactIcons';
import { STATIC_IMAGES } from '../utils/StaticImages';

const SuccessModal = ({successModal,setSuccessModal,setSelectData,msg}) => {
    return (
        <div>
            <AntdComponents.Modal centered open={successModal} onCancel={() => { setSuccessModal(false); setSelectData({}) }} footer={false} width={500} closeIcon={<ReactIcons.CloseIcon />}>
                <div className="text-center my-10">
                    <AntdComponents.Avatar src={STATIC_IMAGES.FORM.RIGHT_TICK} alt="deletIcon" size={80} width={'auto'} />
                    <AntdComponents.Typography className="titleMedium text_truncate">Done</AntdComponents.Typography>
                    <AntdComponents.Typography className="titleSmall text_truncate mt-2">{msg}</AntdComponents.Typography>
                </div>
            </AntdComponents.Modal>

        </div>
    )
}

export default SuccessModal
