// import React from 'react'
// import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import Dummy from './components/Dummy'
// import ProtectRoute from './Routes/ProtectRoute'

// const App = () => {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route element={<ProtectRoute />}>
//           <Route path="/" element={<Dummy />} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   )
// }

// export default App

import * as React from "react";
import CustomToast from "./utils/CustomToast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SuspenseLoader from "./utils/SuspenseLoader";
import { AppRouter } from "./Routes";

function App() {

  // Define routes using createBrowserRouter
  const router = createBrowserRouter(AppRouter)

  // Handle network status changes and reload the page when connection is restored or lost
  React.useEffect(() => {
    
    const handleOnline = () => {
      CustomToast("s", "Network connection restored!", "top-center");
      window.location.reload();
    };

    const handleOffline = () => {
      CustomToast("w", "Network connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Render the app with router and error boundary
  return (
    <React.Suspense fallback={<SuspenseLoader/>}>
        <RouterProvider router={router} />
    </React.Suspense>
  );
}

export default App;