export const NON_AUTH = {
    REGISTER_USER: 'user/auth/register',
    LOGIN: `user/auth/login`,
    SOCIAL_LOGIN: `user/auth/social_login`,
    FORGET_PASSWORD: `user/auth/forgot_password`,
    VERIFY_OTP: `user/auth/verify_otp`,
    RESENT_OTP: `user/auth/resend_otp`,
    RESET_PASSWORD: `user/auth/reset_password`,
    CHANGE_PASSWORD: `user/auth/change_password`,
    LOGOUT: `user/auth/change_password`
}
export const AUTH = {
    ONBOARDING: {
        ONBOARDING_SCREEN_DATA: 'user/onboardingScreen/list',
        ADD_ONBOARDING_SCREEN_DATA: 'user/onboardingScreen/add',
    },
    HOME: {
        FOLDER: {
            GET_FOLDER_LIST: 'user/home/list',
            FOLDER_CREATE: 'user/home/folder/create',
            GET_FOLDER_DETAIL: 'user/home/folder/get',
            FOLDER_UPDATE: 'user/home/folder/update',
            FOLDER_DELETE: 'user/home/folder/delete',
        },
        DOCUMENT: {
            DOCUMENT_UPLOAD: 'user/home/document/upload',
            GET_DOCUMENT_DETAIL: 'user/home/document/get',
            DOCUMENT_UPDATE: 'user/home/document/update',
            DOCUMENT_DELETE: 'user/home/document/delete',
            DOCUMENT_MOVE: 'user/home/document/move',
        },
        HISTORY:'user/home/file/history/list',
        SHARE_FILE:'user/home/shareFile',
    },
    GENERAL: {
        COMMON_DATA: 'common/common_content',
        FEEDBACK_ADD: 'user/feedback/add',
        SUPPORT_ADD: 'user/contactus/add',
    },
    TRASH: {
        TRASH_LIST: 'user/home/trash/list',
        RESTORE_ALL: 'user/home/trash/restore/all',
        DELETE_ALL: 'user/home/trash/delete/all',
        DELETE_ONE: 'user/home/trash/delete',
        RESTORE_ONE: 'user/home/trash/restore',
    },
    PROFILE:{
        USER_DETAIL:'user/auth/details',
        USER_PROFILE_PIC:'user/auth/upload_file',
        USER_PROFILE_UPDATE:'user/auth/profile',
    },
    ALERTS:{
        ALERTS_LIST:'user/notification/alert/list',
    },
    CONTACTS:{
        CONTACTS_LIST:'user/contacts/list',
        CONTACTS_ADD:'user/contacts/addAuthorisedUser',
        CONTACTS_DETAIL:'user/contacts/details',
        CONTACTS_UPDATE:'user/contacts/update',
        CONTACTS_DELETE:'user/contacts/delete',
        CONTACTS_ACCESS_VIEW:'user/contacts/access/view',
        CONTACTS_ACCESS_REMOVE:'user/contacts/access/remove',
        CONTACTS_ACCESS_REMOVE_ALL:'user/contacts/access/remove/all',
    },
    NOTIFICATION:{
        NOTIFICATION_LIST:'user/notification/list',
        NOTIFICATION_DELETE:'user/notification/delete',
        NOTIFICATION_DELETE_ALL:'user/notification/delete_all',
        NOTIFICATION_ACCEPT_REJECT:'user/home/shareFile/response',
    },
    SUBSCRIPTION:{
        GET_SUBSCRIPTION_LISTING:'user/subscription/storage'
    }
}