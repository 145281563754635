import React from 'react'
import { QueryKeys } from '../../../utils/RTKKeys'
import { AUTH } from '../../../utils/Endpoints'
import { useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import * as AntdComponents from 'antd'
const Terms = () => {
    // get  listing
    const { data } = useDynamicQueryQuery({
        endpoint: AUTH.GENERAL.COMMON_DATA,
        params: {},
        key: QueryKeys.COMMON_DATA
    },{refetchOnMountOrArgChange:true})
    let termsData = data?.data?.terms_conditions
    return (
        <div className="" style={{ width: '90%', margin: 'auto', minHeight: '90vh' }}>
            <div className="text-center mt-3">
                <AntdComponents.Typography className="fw-bold" style={{ fontSize: '1.4rem' }}>Terms & Conditions</AntdComponents.Typography>
            </div>
            {
                termsData ?
                    <AntdComponents.Typography className='' style={{ backgroundColor: '#fff' }} dangerouslySetInnerHTML={{ __html: termsData }}>
                    </AntdComponents.Typography>
                    :
                    <AntdComponents.Typography className='text-center text-2xl mt-5'>No Data!</AntdComponents.Typography>
            }
        </div>
    )
}

export default Terms
