import React from 'react'
import UploadStatus from '../Home/UploadStatus'
// import UploadComplete from '../Home/UploadComplete'

const Scan = () => {
  return (
    <div>
      <UploadStatus/>
    </div>
  )
}

export default Scan
