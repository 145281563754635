import React from 'react'
import { Skeleton } from 'antd';


const SkeletonLoader = () => {
  return (
    <div className="flex items-center justify-between bg-GreyLight rounded-10 shadow-homeCardShadow w-full p-2">
      <div className='flex items-center gap-3'>
        <Skeleton.Avatar active shape='square' size={50} />
        <div className="w-full">
          <Skeleton.Input active size='small' />
        </div>
      </div>
    </div>
  )
}

export default SkeletonLoader
