import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../utils/StaticImages'

const LoaderTheme = () => {
  return (
      <AntdComponents.Avatar className='animate-spin-slow' src={STATIC_IMAGES.LoaderImage} size={95} shape='square' />
  )
}

export default LoaderTheme
