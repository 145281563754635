import React from 'react'
import { STATIC_IMAGES } from '../../utils/StaticImages'
import * as AntdComponents from 'antd'
const CommonHeader = () => {
    return (
        <div className='text-center'>
                <AntdComponents.Image src={STATIC_IMAGES.LOGO} preview={false} className='logo_' />
                <AntdComponents.Typography className='titleLarge m-auto w-[85%] md:w-full'>Safely store what matters to you</AntdComponents.Typography>
        </div>
    )
}

export default CommonHeader
