import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import * as AntdComponents from "antd"
import { STATIC_IMAGES } from '../utils/StaticImages'
import { RouterKeys } from '../Routes/RouterKeys'
const OnBoardingLayout = () => {
  const [sideImageUrl, setSideImageUrl] = useState('')
  const location = useLocation()
  useEffect(() => {
    let pathname = location.pathname
    console.log('pathname',pathname);
    if (pathname === `/${RouterKeys.NON_Auth.ONBOARDING.WELCOME}`) {
      setSideImageUrl(STATIC_IMAGES.ONBOARDING.WELCOME)
    } else if (pathname === `/${RouterKeys.NON_Auth.ONBOARDING.AREA_PROTECT}`) {
      setSideImageUrl(STATIC_IMAGES.ONBOARDING.AREA_PROTECT)
    } else if (pathname === `/${RouterKeys.NON_Auth.ONBOARDING.WEATHER}`) {
      setSideImageUrl(STATIC_IMAGES.ONBOARDING.WEATHER)
    } else if (pathname === `/${RouterKeys.NON_Auth.ONBOARDING.HOSPITAL}`) {
      setSideImageUrl(STATIC_IMAGES.ONBOARDING.MEDICAL)
    } else if (pathname === `/${RouterKeys.NON_Auth.ONBOARDING.THANK_YOU}`) {
      setSideImageUrl(STATIC_IMAGES.ONBOARDING.THANKYOU)
    }
  }, [location])
  return (
    <div className='bg-background-gradient min-h-screen'>
      <AntdComponents.Row>
        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} className='hidden md:block'>
          <div className="bg-contain bg-center bg-no-repeat w-full h-[95vh]" style={{ backgroundImage: `url(${sideImageUrl})`, height: '70vh' }}></div>
        </AntdComponents.Col>
        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} className='m-auto px-3 md:px-20 py-5 md:py-0'>
          <div className="bg-White rounded-20 flex items-center flex-col justify-center text-center py-12 min-h-600">
            <AntdComponents.Image src={STATIC_IMAGES.LOGO} preview={false} className='logo_' />
            <Outlet />
          </div>
        </AntdComponents.Col>
      </AntdComponents.Row>
    </div>
  )
}

export default OnBoardingLayout
