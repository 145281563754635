// import React from 'react'
// import Header from '../components/Header'
// import { Outlet } from 'react-router-dom'

// const ProtectRoute = () => {
//     return (
//         <div>
//             <Header />
//             <Outlet/>
//         </div>
//     )
// }

// export default ProtectRoute

import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouterKeys } from './RouterKeys';
import { useSelector } from 'react-redux';

const ProtectRoute = ({ children }) => {
    const accessToken = useSelector((state) => state?.auth?.auth_data?.token)
    console.log('accessToken', accessToken);

    // Redirect to login page if the user is not authenticated
    if (accessToken) {
        return children;
    } else {
        return <Navigate to={RouterKeys.Auth.CONTINUE} />;
    }


    // Redirect to login if trying to access a non-existent route and not logged in
    // if (!accessToken) {
    //     return <Navigate to={RouterKeys.Auth.CONTINUE} />;
    // }

    // return children;
};

export default ProtectRoute;