import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../../../utils/ReactIcons'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { capitalizeType, fileUrl, getType } from '../../../utils/Constant'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'
import CustomToast from '../../../utils/CustomToast'

const TrustedContactModal = ({ trustedModalOpen, setTrustedModalOpen, setMainOpen, selectData,setShareModalOpen }) => {
    const [userNotExistModal,setUserNotExistModal] = React.useState(false)
    // send file
    const [triggerSendClick] = useDynamicMutationMutation();
    // get history detail
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.CONTACTS.CONTACTS_LIST,
        params: {},
        key: QueryKeys.CONTACT_LIST
    },
        { refetchOnMountOrArgChange: true }
    )
    let contactsData = data?.data

    const handleSend = async (shareData) => {
        if(shareData?.user_id){
            let fileType = capitalizeType(getType(selectData))
            let payload = {
                "shared_with": [shareData?._id],
                "file_id": selectData?._id,
                "file_type": fileType
            }
            let requestData = {
                endpoint: AUTH.HOME.SHARE_FILE,
                method: 'POST',
                body: payload,
            };
    
            try {
                const { data, error } = await triggerSendClick(requestData);
                if (data) {
                    CustomToast('s', data?.message);
                    setMainOpen(false)
                    setTrustedModalOpen(false)
                } else {
                    CustomToast('e', error?.data?.message || 'Something went wrong');
                }
            } catch (err) {
                CustomToast('e', 'An unexpected error occurred.');
            }
        }else{
            setUserNotExistModal(true)
        }
    }
    return (
        <>
            <AntdComponents.Modal
                open={trustedModalOpen}
                onCancel={() => { setTrustedModalOpen(false); setMainOpen(false) }}
                footer={false}
                centered
                closeIcon={<ReactIcons.CloseIcon onClick={() => { setTrustedModalOpen(false); setMainOpen(false) }} />}
                width={500}
            >
                <div className="h-60 overflow-y-auto overflow-x-hidden">
                    <AntdComponents.Typography className='titleMedium'>Trusted Contacts</AntdComponents.Typography>
                    {
                        isLoading ?
                            <AntdComponents.Row gutter={[20, 20]}>
                                {
                                    [1, 2, 3]?.map((_, index) => {
                                        return (
                                            <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
                                                <SkeletonLoader />
                                            </AntdComponents.Col>
                                        )
                                    })
                                }
                            </AntdComponents.Row>
                            :
                            <>
                                {
                                    contactsData?.result?.length === 0 ?
                                        <div className='text-center mt-5'>
                                            <AntdComponents.Avatar src={STATIC_IMAGES.HISTORY} size={80} shape='square' />
                                            <AntdComponents.Typography className='titleSmall'>No History</AntdComponents.Typography>
                                        </div>
                                        :
                                        <>
                                            {
                                                contactsData?.result?.map((item, index) => {
                                                    let image = item?.profile_pic ? `${fileUrl}${item?.profile_pic}` : <ReactIcons.profileIcon className={'text-DarkGrey'} style={{ fontSize: 25 }} />
                                                    return (
                                                        <div className="flex items-center justify-between" key={index}>
                                                            <div className="flex items-center gap-3 mt-3">
                                                                <AntdComponents.Avatar src={image} size={50} shape={'circle'} className='bg-GreyLight2' />
                                                                <div>
                                                                    <AntdComponents.Typography className="paragraph text-start text_truncate max-w-66 md:max-w-120 lg:max-w-200 ">
                                                                        {item?.first_name} {item?.last_name}
                                                                    </AntdComponents.Typography>
                                                                </div>
                                                            </div>
                                                            <AntdComponents.Avatar src={STATIC_IMAGES.SEND} size={50} shape='square' role='button' onClick={() => handleSend(item)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </>
                    }
                </div>

            </AntdComponents.Modal>

            {
                userNotExistModal&&
                <AntdComponents.Modal
                    open={userNotExistModal}
                    onCancel={() => { setUserNotExistModal(false);}}
                    footer={false}
                    centered
                    closeIcon={<ReactIcons.CloseIcon onClick={() => { setUserNotExistModal(false); }} />}
                    width={500}
                >
                    <AntdComponents.Typography className='titleMedium'>Share Externally</AntdComponents.Typography>
                    <div className="mt-3">
                        <AntdComponents.Typography className='paragraph'>This user is not registered with Vault PD.</AntdComponents.Typography>
                        <AntdComponents.Typography className='paragraph'>Do you want to share it externally?</AntdComponents.Typography>
                    </div>
                    <div className="text-center flex items-center gap-4 justify-center mt-6">
                        <AntdComponents.Typography className='text-Red paragraph font-semibold cursor-pointer' onClick={()=>setUserNotExistModal(false)}>CANCEL</AntdComponents.Typography>
                        <AntdComponents.Typography className='text-Purple paragraph font-semibold cursor-pointer' onClick={()=>{setShareModalOpen(true);setTrustedModalOpen(false);setUserNotExistModal(false)}}>SHARE EXTERNALLY</AntdComponents.Typography>
                    </div>
                </AntdComponents.Modal>
            }
        </>
    )
}

export default TrustedContactModal
