import React from 'react'
import * as AntdComponents from 'antd'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { QueryKeys } from '../../../utils/RTKKeys'
import { AUTH } from '../../../utils/Endpoints'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import CardCommon from '../../../components/CardCommon'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'
import { useSelector } from 'react-redux'
import moment from 'moment'
import CommonDelete from '../../../components/CommonDelete'
import CustomToast from '../../../utils/CustomToast'
import { ReactIcons } from '../../../utils/ReactIcons'
import CustomDivider from '../../../components/CustomDivider'
import useIntersectionObserver from '../../../Hook/useIntersectionObserver'


const popoverArr = [
    {
        title: 'Delete Notification',
        icon: STATIC_IMAGES.DELETE,
        type: 'delete'
    },
]
const Alerts = () => {
    const userData = useSelector((state) => state?.auth?.auth_data)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [popOverOpenData, setPopOverOpenData] = React.useState({})
    const [listData, setListData] = React.useState([])

    // pagination and endless scrolling states
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true)
    const loadMoreRef = React.useRef(null);
    // trash listing
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.ALERTS.ALERTS_LIST,
        params: { device_id: userData?.device_id, page: page, limit: 14 },
        key: QueryKeys.ALERT_LIST
    },
        { refetchOnMountOrArgChange: true }
    )


    // data set with scrolling
    React.useEffect(() => {
        let mainData = data?.data?.result
        if (mainData?.length === 0) {
            setHasMore(false);
            // setLoadMore(false)
        } else {
            setListData((prevData) => {
                if (prevData?.length > 0) {
                    return [...prevData, ...mainData]
                } else {
                    return mainData
                }
            });
        }
    }, [data])
    // endless scrolling
    useIntersectionObserver({
        targetRef: loadMoreRef,
        hasMore,
        isLoading,
        setPage,
    })


    // delete notification
    const [triggerDeleteMutation, { isLoading: deleteLoading }] = useDynamicMutationMutation();


    // threeDot content
    const PopOverContent = ({ data }) => {
        const openFunction = (e, type) => {
            e.stopPropagation()
            setPopOverOpenData({});
            switch (type) {
                case 'view': {
                    CustomToast('e', "You can't share the folder externally.")
                }
                    break;
                case 'delete': {
                    deleteNotification(data, 'single')
                }
                    break;
                default:
                    CustomToast('e', "Something wrong.")
            }
        }
        return (
            <div className="p-3 w-96">
                <div className="flex justify-end">
                    <ReactIcons.CloseIcon onClick={(e) => { e.stopPropagation(); setPopOverOpenData({}) }} />
                </div>
                <div className="flex gap-4">
                    <AntdComponents.Avatar src={STATIC_IMAGES.FOLDER} size={40} />
                    <div className="">
                        <AntdComponents.Typography className='titleSmall text_truncate max-w-66 md:max-w-120 lg:max-w-200'>{data?.message}</AntdComponents.Typography>
                        <AntdComponents.Typography className='text-description font-normal text-DarkGrey'>{moment(data?.createdAt).format('ddd MM-DD-YYYY h:mm')}</AntdComponents.Typography>
                    </div>
                </div>
                <CustomDivider className={'mt-4'} />
                {
                    popoverArr?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center gap-5 mt-3" role='button' tabIndex={0}
                                onClick={(e) => openFunction(e, item?.type)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        openFunction(e, item?.type)
                                    }
                                }}
                            >
                                <AntdComponents.Avatar src={item?.icon} size={40} />
                                <AntdComponents.Typography className='titleSmall font-normal'>{item?.title}</AntdComponents.Typography>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    // delete notifications
    const deleteNotification = async (item = {}, type = 'multiple') => {
        let payloadSingleDelete = {
            notification_id: [item?._id]
        }

        let payloadAllDelete = {
            type: 'alert'
        }
        let requestData = {
            endpoint: type === 'single' ? AUTH.NOTIFICATION.NOTIFICATION_DELETE : AUTH.NOTIFICATION.NOTIFICATION_DELETE_ALL,
            method: 'DELETE',
            body: type === 'single' ? payloadSingleDelete : payloadAllDelete,
            // key: QueryKeys.ALERT_LIST
        };

        try {
            const { data, error } = await triggerDeleteMutation(requestData);
            if (data?.data) {
                CustomToast('s', data?.message);
                setDeleteModal(false)
                let updateArr = listData?.filter((ele) => item?._id !== ele?._id)
                setListData(updateArr)
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    return (
        <>
            <div className='content_common'>
                <div className={`flex items-center ${listData?.length == 0 ? 'justify-center' : 'justify-between'} `}>
                    <p></p>
                    <AntdComponents.Typography className='titleMedium'>Alerts</AntdComponents.Typography>
                    {
                        listData?.length > 0 &&
                        <AntdComponents.Tooltip title='Clear All'>
                            <AntdComponents.Avatar src={STATIC_IMAGES.CLEAR_ALL} shape='square' size={60} role='button' onClick={() => setDeleteModal(true)} />
                        </AntdComponents.Tooltip>
                    }
                </div>
                {
                    isLoading ?
                        <AntdComponents.Row gutter={[20, 20]}>
                            {
                                [1, 2, 3]?.map((_, index) => {
                                    return (
                                        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                                            <SkeletonLoader />
                                        </AntdComponents.Col>
                                    )
                                })
                            }
                        </AntdComponents.Row>
                        :
                        <>
                            {
                                listData?.length == 0 ?
                                    <div className='text-center mt-5'>
                                        <AntdComponents.Avatar src={STATIC_IMAGES.ALERT_ICON} size={110} shape='square' />
                                        <AntdComponents.Typography className='titleSmall'>No Alerts Detected</AntdComponents.Typography>
                                    </div>
                                    :
                                    <>
                                        <AntdComponents.Row gutter={[20, 20]} className='mt-5'>
                                            {
                                                listData?.map((item, index) => {
                                                    return (
                                                        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} key={index} className='cursor-pointer'>
                                                            <CardCommon data={item} image={STATIC_IMAGES.ALERT_ICON} size={50} shape={'square'} name={item?.message} popOverOpenData={popOverOpenData} setPopOverOpenData={setPopOverOpenData} PopOverContent={PopOverContent} otherContent={moment(item?.createdAt).format('lll')} byDetailPage={true} truncate={false} />
                                                        </AntdComponents.Col>
                                                    )
                                                })
                                            }
                                        </AntdComponents.Row>
                                        {/* {hasMore && <div ref={loader} className='h-6 my-10'></div>} */}
                                    </>
                            }
                            {/* {!hasMore && <AntdComponents.Typography className='text-center titleSmall mt-3'>No more data!</AntdComponents.Typography>} */}
                        </>
                }

                {
                    deleteModal &&
                    <CommonDelete deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteMessage={'Do you want to clear all the alerts?'} deleteFunc={deleteNotification} loading={deleteLoading} />
                }
            </div>
            {/* scrolling */}
            {hasMore && <div ref={loadMoreRef} className=''></div>}

        </>
    )
}

export default Alerts
