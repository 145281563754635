import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../utils/ReactIcons'
import { STATIC_IMAGES } from '../utils/StaticImages'
import { acceptImageType } from '../utils/Constant'
import { AUTH } from '../utils/Endpoints'
import CustomToast from '../utils/CustomToast'
import { QueryKeys } from '../utils/RTKKeys'
import CustomButton from './CustomButton'

const UploadModal = (
    {
        uploadModalOpen,
        setUploadModalOpen,
        setBlobImages,
        documentAdd,
        setCompleteStatus,
        isFolder,
        createFolderFunc,
        checkListKeyName = ''
    }) => {
    const [folderName, setFolderName] = React.useState('')
    const [errorMsg, setErrorMsg] = React.useState(false)
    React.useEffect(() => {
        if (checkListKeyName != '') {
            setFolderName(`${checkListKeyName} Documents`)
        }
    }, [])

    const folderNameHandle = (e) => {
        let value = e.target.value
        setFolderName(value)
        if (value) {
            setErrorMsg(false)
        } else {
            setErrorMsg(true)
        }
    }

    const folderError = (type = '') => {
        if (folderName.trim().length != 0 || !isFolder) {
            setErrorMsg(false)
            if (type == 'empty') {
                createFolder()
            }
        } else {
            setErrorMsg(true)
        }
    }

    const onFileUpload = (e) => {
        let files = Array.from(e.target.files)
        const blobUrls = files.map(file => {
            // Generate a Blob URL for each file      
            let obj = {
                url: URL.createObjectURL(file),
                name: file?.name,
                type: file?.type
            }
            return obj
        });
        setBlobImages(blobUrls);
        if (folderName) {
            createFolder(files)
        } else {
            let filePayload = {
                folder_id: '',
                documents: files,
                is_scanned: false
            }
            uploadDocument(filePayload)
        }
    }

    // create folder and hit document upload function
    const createFolder = async (files) => {
        let payload = {
            folder_name: folderName.trim(),
        };

        let requestData = {
            endpoint: AUTH.HOME.FOLDER.FOLDER_CREATE,
            method: 'POST',
            body: payload,
            key: QueryKeys.FOLDER_LIST
        };

        try {
            const { data, error } = await createFolderFunc(requestData);
            if (data) {
                setUploadModalOpen(false)
                if (files?.length > 0) {
                    let filePayload = {
                        folder_id: data?.data?._id,
                        documents: files,
                        is_scanned: false
                    }
                    uploadDocument(filePayload);
                }
                setFolderName('')
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    };

    // document uploading handling
    const uploadDocument = async (filePayload) => {
        setUploadModalOpen(false)
        setCompleteStatus(true)
        let formData = new FormData()
        formData.append('folder_id', filePayload?.folder_id)
        filePayload?.documents?.map(file => formData.append('documents', file))
        // formData.append('is_scanned', filePayload?.is_scanned)
        let requestData = {
            endpoint: AUTH.HOME.DOCUMENT.DOCUMENT_UPLOAD,
            method: 'POST',
            body: formData,
            key: [QueryKeys.FOLDER_DETAIL,QueryKeys.FOLDER_LIST]
        };

        try {
            const { error } = await documentAdd(requestData);
            if (error) {
                setCompleteStatus(false)
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            setCompleteStatus(false)
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    const handleCancel = () => {
        if (setFolderName) {
            setFolderName('');
            setUploadModalOpen(false)
        }
    }

    return (
        <AntdComponents.Modal centered open={uploadModalOpen} onCancel={() => handleCancel()} footer={false} closeIcon={<ReactIcons.CloseIcon onClick={() => handleCancel()} />}>
            {
                isFolder &&
                <>
                    <div className="">
                        <AntdComponents.Typography className='text-description font-semibold text-center'>Folder Name</AntdComponents.Typography>
                        <AntdComponents.Input className='border-0 border-b-2 rounded-none mb-2' value={folderName} onChange={(e) => { folderNameHandle(e) }} />
                        <AntdComponents.Typography className='text-para text-Red'>{errorMsg && "Please enter folder name"}</AntdComponents.Typography>
                    </div>
                </>
            }
            <AntdComponents.Typography className='text-description text-center mt-1'>Choose what you would like to upload</AntdComponents.Typography>
            <div className="flex items-center justify-center gap-16 mt-3">
                {/* gallery */}
                <input id="imageInput" multiple
                    // disabled={((!errorMsg) || !isFolder) ? false : true}
                    className='hidden'
                    accept={acceptImageType}
                    type="file"
                    onChange={onFileUpload}
                />
                <label htmlFor="imageInput" className="cursor-pointer">
                    <div className="text-center"
                    // role='button' tabIndex={0}
                    // onClick={(e) => folderError()}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter' || e.key === ' ') {
                    //         folderError()
                    //     }
                    // }}
                    >
                        <AntdComponents.Avatar src={STATIC_IMAGES?.GALLERY} size={60} shape='square' />
                        <AntdComponents.Typography className='text-description text-center'>Gallery</AntdComponents.Typography>
                    </div>
                </label>
                {/* camera */}
                <div className="text-center">
                    <AntdComponents.Avatar src={STATIC_IMAGES?.CAMERA} size={60} shape='square' />
                    <AntdComponents.Typography className='text-description text-center'>Camera</AntdComponents.Typography>
                </div>
                {/* file */}
                <input id="fileInput" multiple
                    // disabled={((!errorMsg) || !isFolder) ? false : true}
                    type="file"
                    className='hidden'
                    onChange={(e) => onFileUpload(e)}
                />
                <label htmlFor="fileInput" className="cursor-pointer ">
                    <div className="text-center"
                    // role='button' tabIndex={0}
                    // onClick={(e) => folderError()}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter' || e.key === ' ') {
                    //         folderError()
                    //     }
                    // }}
                    >
                        <AntdComponents.Avatar src={STATIC_IMAGES?.FILE_ADD} size={60} shape='square' />
                        <AntdComponents.Typography className='text-description text-center'>File</AntdComponents.Typography>
                    </div>
                </label>
            </div>
            {
                isFolder &&
                <div className="text-center mt-5">
                    <CustomButton title='Create Empty Folder' onClick={() => folderError('empty')} />
                </div>
            }
        </AntdComponents.Modal>
    )
}

export default UploadModal
