import { useDynamicMutationMutation, useDynamicQueryQuery } from '../service/apiSlice';
// import { useSelector } from 'react-redux';


export const useCustomDynamicQuery = ({ endpoint, params, key, skip = false }) => {
    const { data, error, isLoading,refetch } = useDynamicQueryQuery(
        { endpoint, params, key },
        {skip,refetchOnMountOrArgChange: true}
    );
    
    return { data, error, isLoading,refetch };
};

export const useMutation = (key) => {
    // Dynamic POST/PUT/DELETE request
    const [triggerMutation, { data, error, isLoading }] = useDynamicMutationMutation();
    console.log('shhss',data);
    
    const handleMutation = async ({ endpoint, method, body }) => {
        let headers = {
            'Content-Type': 'application/json',
        };

        // If the body is a FormData object, remove the Content-Type header
        if (body instanceof FormData) {
            delete headers['Content-Type'];
        }
        try {
            const result = await triggerMutation({
                endpoint,
                method,
                body,
                headers,
            });            
            console.log('result?.data',result?.data);
            return { data: result?.data, error: result?.error }
        } catch (error) {
            console.error('Mutation failed', error);
            return { data: null, error }
        }
    };

    return {
        handleMutation,
        data,
        error,
        isLoading,
    };
} 