import React from 'react'
import * as AntdComponents from 'antd'
import CustomButton from './CustomButton'
import { STATIC_IMAGES } from '../utils/StaticImages'
import { ReactIcons } from '../utils/ReactIcons'

const CommonWarning = ({ warningModal, setWarningModal, handleClick }) => {
  const [checkValue, setCheckValue] = React.useState(false)
  const handleSend = () => {
    if (checkValue) {
      handleClick()
    }
  }
  return (
    <div>
      <AntdComponents.Modal centered open={warningModal} onCancel={() => setWarningModal(false)} footer={false} width={550} closeIcon={<ReactIcons.CloseIcon />}>
        <div className="text-center">
          <AntdComponents.Avatar src={STATIC_IMAGES.WARNING} size={120} shape='square' />
          <AntdComponents.Typography className='titleMedium text-center'>Disclaimer</AntdComponents.Typography>
          <div className="mt-4 w-85 m-auto">
            <AntdComponents.Typography className='paragraph '>You are about to share private information <br />with John Doe</AntdComponents.Typography>
            <AntdComponents.Typography className='paragraph mt-4'>Do not share this private information with <br />just anyone.</AntdComponents.Typography>
            <AntdComponents.Typography className='paragraph mt-4'>This action cannot be reversed.</AntdComponents.Typography>
            <AntdComponents.Typography className='paragraph font-bold mt-4'>Are you sure you want to send this <br />document?</AntdComponents.Typography>
          </div>
          <div className="text-start px-3 mt-3">
            <div className="flex items-center gap-4">
              <AntdComponents.Checkbox checked={checkValue} onChange={(value) => setCheckValue(value)} />
              <AntdComponents.Typography className='paragraphSmall font-normal leading-6'>I understand. I am authorized to send this information, and I am responsible for improper sharing of private information.</AntdComponents.Typography>
            </div>
          </div>
          <CustomButton title={'Send'} className={'mt-5'} onClick={() => handleSend()} />
        </div>
      </AntdComponents.Modal>
    </div>
  )
}

export default CommonWarning
