import React from 'react'
import * as AntdComponents from 'antd'
import OutlineButton from '../../../components/OutlineButton'
import CustomButton from '../../../components/CustomButton'
import { useDynamicMutationMutation } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import CustomToast from '../../../utils/CustomToast'
import { useSelector } from 'react-redux'
import { whiteSpaceError } from '../../../utils/Constant'


const SupportAndFeedback = () => {
  const userData = useSelector((state) => state?.auth?.auth_data)

  const [form] = AntdComponents.Form.useForm()
  const [activeTab, setActiveTab] = React.useState('Support')
  const [submitMutation, { isLoading }] = useDynamicMutationMutation();

  React.useEffect(() => {
    form.setFieldsValue({
      'name': userData?.user_name,
      'email': userData?.email,
    })
  }, [activeTab])

  const handleFinish = async (values) => {
    let payload = {
      "email": values?.email.trim(),
      "name": values?.name.trim()
    };


    let requestData = {
      method: 'POST',
      body: payload,
    };

    if (activeTab === 'Support') {
      payload.message = values?.message
      requestData.endpoint = AUTH.GENERAL.SUPPORT_ADD
    } else {
      payload.feedback = values?.message
      requestData.endpoint = AUTH.GENERAL.FEEDBACK_ADD
    }

    try {
      const { data, error } = await submitMutation(requestData);
      if (data) {
        form.setFieldsValue({ 'message': '' })
        CustomToast('s', data?.message);
      }
      if (error) {
        CustomToast('e', error?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }
  }
  return (
    <div className='content_common'>
      <div className="flex items-center justify-center w-full mt-5">
        <OutlineButton title={'Support'} classNameDiv={'w-full rounded-none'} className={`rounded-none ${activeTab == 'Support' ? 'bg-transparent text-White' : 'bg-White'}`} onClick={() => { setActiveTab('Support'); form.setFieldsValue({ 'message': '' }) }} />
        <OutlineButton title={'Feedback'} classNameDiv={'w-full rounded-none'} className={`rounded-none ${activeTab == 'Feedback' ? 'bg-transparent text-White' : 'bg-White'}`} onClick={() => { setActiveTab('Feedback'); form.setFieldsValue({ 'message': '' }) }} />
      </div>
      <div className="mt-5">
        <div className="text-center">
          <AntdComponents.Typography className='titleMedium'>{activeTab}</AntdComponents.Typography>
          <AntdComponents.Typography className='paragraph mt-3'>
            {
              activeTab === 'Support' ?
                <>
                  Technical support is available Monday through Saturday <br /> from 9:00 AM to 9:00 PM CST.
                </>
                :
                <>
                  Share any ideas or concerns with us to help us improve <br />for the better.
                </>
            }
          </AntdComponents.Typography>
          <div className="mt-8 w-full md:w-1/2 m-auto">
            <AntdComponents.Form form={form} layout='vertical' onFinish={(values) => handleFinish(values)} requiredMark={false}>
              <AntdComponents.Row gutter={[20, 20]}>
                <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <AntdComponents.Form.Item name="name" autoComplete="off" className='mb-1'
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter your name!',
                      },
                      {
                        validator: (_, value) => whiteSpaceError(value)
                      },
                    ]}>
                    <AntdComponents.Input className='input-box' placeholder='Your name' readOnly />
                  </AntdComponents.Form.Item>
                </AntdComponents.Col>
                <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <AntdComponents.Form.Item name="email" autoComplete="off" className='mb-1'
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter a valid email!',
                      },
                      {
                        required: true,
                        message: 'Please Enter your email!',
                      },
                      {
                        validator: (_, value) => whiteSpaceError(value)
                      },
                    ]}>
                    <AntdComponents.Input className='input-box' placeholder='name@domain.com' readOnly />
                  </AntdComponents.Form.Item>
                </AntdComponents.Col>
                <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <AntdComponents.Form.Item name="message" autoComplete="off"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter your message!',
                      },
                      {
                        validator: (_, value) => whiteSpaceError(value)
                      },
                    ]}>
                    <AntdComponents.Input.TextArea rows={8} className='input-box' placeholder='Your Message' />
                  </AntdComponents.Form.Item>
                </AntdComponents.Col>
              </AntdComponents.Row>
              <CustomButton title={activeTab === 'Support' ? 'Send Email' : 'Send Feedback'} isLoading={isLoading} htmlType='submit' />
            </AntdComponents.Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportAndFeedback
