import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../utils/StaticImages'
import { ReactIcons } from '../utils/ReactIcons'
import CustomButton from './CustomButton'
import OutlineButton from './OutlineButton'
const CommonDelete = ({ deleteModal, setDeleteModal, deleteMessage, deleteFunc, loading }) => {

    const deleteClick = () => {
        deleteFunc()
    }
    return (
        <div className="">
            <AntdComponents.Modal centered open={deleteModal} onCancel={() => setDeleteModal(false)} footer={false} width={500} closeIcon={<ReactIcons.CloseIcon />}>
                <div className="text-center my-10">
                    <AntdComponents.Image preview={false} src={STATIC_IMAGES.DELETE} alt="deletIcon" height={90} width={'auto'} />
                    <AntdComponents.Typography className="titleSmall text_truncate">{deleteMessage}</AntdComponents.Typography>
                    <div className="mt-4">
                        <CustomButton title={'Delete'} isLoading={loading} onClick={() => deleteClick()} />
                        <OutlineButton title={'Cancel'} className={'text-Purple bg-White'} classNameDiv='m-auto mt-5 w-full md:w-64' onClick={() => { setDeleteModal(false) }} />
                    </div>
                </div>
            </AntdComponents.Modal>
        </div>
    )
}
export default CommonDelete