import React from 'react'
import * as AntdComponents from "antd"
import { useNavigate } from 'react-router-dom'
import { RouterKeys } from '../../../Routes/RouterKeys'
import CustomButton from '../../../components/CustomButton'

const Welcome = () => {
  const navigate = useNavigate()
  return (
    <div>
      <AntdComponents.Typography className='titleMedium font-bold'>Welcome</AntdComponents.Typography>
      <div className="mt-2 px-5 md:px-0">
        <AntdComponents.Typography className='paragraph'>Your vault helps you protect those you care about <br />and your things.</AntdComponents.Typography>
        <AntdComponents.Typography className='paragraph mt-5'>To get started, let’s explore how you can prepare <br />ahead of challenges.</AntdComponents.Typography>
        <div className="mt-12">
          <CustomButton title='Continue'  onClick={() => navigate(`/${RouterKeys.NON_Auth.ONBOARDING.AREA_PROTECT}`)} />
        </div>
      </div>
    </div>
  )
}

export default Welcome
