import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../utils/ReactIcons'
import { RouterKeys } from '../Routes/RouterKeys';
import { useNavigate } from 'react-router-dom';
// import { fileUrl } from '../utils/Constant';

const CardCommon = ({
    data,
    image,
    size,
    shape,
    name,
    status,
    otherContent = '',
    cardPadding = 'p-4',
    popOverOpenData = {},
    setPopOverOpenData = () => { },
    PopOverContent,
    placementPopOver = 'bottomRight',
    docUploading,
    byDetailPage,
    truncate = true,
    acceptDecline=()=>{},
    btnLoading=false
}) => {
    const navigate = useNavigate()
    const handleFilePreview = (file) => {
        navigate(`${RouterKeys.NON_Auth.HOME.DOCUMENTS_VIEW}?id=${file?._id}`)
    };

    return (
        <>
            <div className={`flex items-center justify-between bg-White rounded-10 shadow-homeCardShadow ${cardPadding}`}
                tabIndex={0}
                role='button'
                onClick={() => { !byDetailPage && handleFilePreview(data) }}
                onKeyDown={(e) => {
                    if (!byDetailPage) {
                        if (e.key === 'Enter' || e.key === ' ') {
                            handleFilePreview(data)
                        }
                    }
                }}
            >
                <div className="flex items-center gap-3">
                    <AntdComponents.Avatar src={image} size={size} shape={shape} />
                    <div>
                        <AntdComponents.Typography className={`paragraph  text-start ${truncate && 'text_truncate max-w-66 md:max-w-120 lg:max-w-200'}`}>
                            {name}
                        </AntdComponents.Typography>
                        {status && (
                            <AntdComponents.Typography className="text-para text-start text-Purple font-semibold font-Poppins">
                                {status}
                            </AntdComponents.Typography>
                        )}
                        {otherContent && (
                            <AntdComponents.Typography className="description text-start font-semibold">
                                {otherContent}
                            </AntdComponents.Typography>
                        )}
                    </div>
                </div>
                <div className="flex items-start gap-4">
                    {
                        (data?.user_responded==false) &&
                        <div className="flex items-center gap-3">
                            <AntdComponents.Button type='ghost' className='notification_button bg-Green'  onClick={()=>acceptDecline(data,true)}>Accept</AntdComponents.Button>
                            <AntdComponents.Button type='ghost' className='notification_button bg-Red'  onClick={()=>acceptDecline(data,false)}>Decline</AntdComponents.Button>
                        </div>
                    }
                    {!docUploading && (
                        <AntdComponents.Popover
                            className="p-0"
                            open={popOverOpenData?._id === data?._id}
                            content={<PopOverContent data={data} />}
                            placement={placementPopOver}
                            arrow={false}
                            trigger={'click'}
                        >
                            <ReactIcons.ThreeDotIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPopOverOpenData(data);
                                }}
                            />
                        </AntdComponents.Popover>
                    )}
                </div>
            </div>
        </>
    );
};

export default CardCommon;
