import React from 'react'
import { STATIC_IMAGES } from '../../utils/StaticImages'
import * as AntdComponents from 'antd'
import { RouterKeys } from '../../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '../../redux/hook/useApi'
import { NON_AUTH } from '../../utils/Endpoints'
import CustomToast from '../../utils/CustomToast'
import { AuthData } from '../../redux/slices/authSlice'
import CommonHeader from './CommonHeader'
import CustomButton from '../../components/CustomButton'
import SocialLogin from './socialLogin/SocialLogin'
import { DeviceUUID } from 'device-uuid';

const Login = () => {
    const deviceId = new DeviceUUID().get();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { handleMutation, isLoading } = useMutation('login');
    const handleSubmit = async (values) => {
        let payload = {
            "device_country": "US",
            "device_name": "web",
            "device_id": deviceId,
            "os_type": "web",
            "password": values?.password,
            "email": values?.email
        }
        const { data, error } = await handleMutation({
            endpoint: NON_AUTH?.LOGIN,
            method: 'POST',
            body: payload,
        });
        console.log('data?.data', data);

        if (data?.data?.token) {
            CustomToast('s', data?.message)
            dispatch(AuthData(data?.data));
            navigate(`/${RouterKeys.NON_Auth.HOME.HOME}`)
        } else {
            CustomToast('e', error?.data?.message || 'Login failed')
        }
    }
    return (
        <div className='auth-main-div'>
            <CommonHeader />
            <div className='text-center auth-form rounded-10 shadow-formShadow p-4'>
                <div className="">
                    <div className="bg-Grey rounded-10 p-3">
                        <AntdComponents.Typography className='titleMedium text-center mb-4'>Login</AntdComponents.Typography>
                        <SocialLogin />
                    </div>
                    <div className="inputs-container">
                        <AntdComponents.Form name="Auth" initialValues={{ remember: true, }} onFinish={handleSubmit} requiredMark={false} className='mt-5' layout='vertical'>
                            <AntdComponents.Form.Item name="email" autoComplete="off"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please Enter your email!',
                                    },
                                ]}>
                                <AntdComponents.Input className='input-box' placeholder='name@domain.com' prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.EMAIL} size={30} shape='square' />} />
                            </AntdComponents.Form.Item>
                            <AntdComponents.Form.Item name="password" autoComplete="off"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password!',
                                    },
                                ]}>
                                <AntdComponents.Input.Password className='input-box' placeholder='Password' prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.KEY} size={30} shape='square' />} iconRender={(visible) => (visible ? <AntdComponents.Avatar role='button' src={STATIC_IMAGES.FORM.EYE_ONN} size={40} shape='square' /> : <AntdComponents.Avatar role='button' src={STATIC_IMAGES.FORM.EYE_OFF} size={40} shape='square' />)} />
                            </AntdComponents.Form.Item>
                            <div className="mt-14">
                                <CustomButton isLoading={isLoading} htmlType='submit' title='Login' className={'mb-6'} />
                                <AntdComponents.Typography className='paragraph font-semibold text-TermsColor' role='button' onClick={() => navigate(`/${RouterKeys.Auth.REGISTER}`)}>Create a new account</AntdComponents.Typography>
                                <AntdComponents.Typography className='paragraph font-semibold mt-3' role='button' onClick={() => navigate(`/${RouterKeys.Auth.FORGOT_PASSWORD}`)}>Forgot password?</AntdComponents.Typography>
                            </div>
                        </AntdComponents.Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
