import React from 'react'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { fileUrl } from '../../../utils/Constant'
import CardCommon from '../../../components/CardCommon'
import CustomToast from '../../../utils/CustomToast'
import CustomDivider from '../../../components/CustomDivider'
import { ReactIcons } from '../../../utils/ReactIcons'
import CustomButton from '../../../components/CustomButton'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'
import OutlineButton from '../../../components/OutlineButton'
import SuccessModal from '../../../components/SuccessModal'
import useIntersectionObserver from '../../../Hook/useIntersectionObserver'

const popoverArr = [
    {
        title: 'View access level',
        icon: STATIC_IMAGES.CONTACTS.VIEW,
        type: 'view_access'
    },
    {
        title: 'Remove contact',
        icon: STATIC_IMAGES.CONTACTS.MINUS_CIRCLE,
        type: 'remove'
    },
    {
        title: 'Edit contact info',
        icon: STATIC_IMAGES.EDIT,
        type: 'edit'
    },
]
const Contacts = () => {
    const navigate = useNavigate()
    const [selectData, setSelectData] = React.useState({})
    const [removeModal, setRemoveModal] = React.useState(false)
    const [successModal, setSuccessModal] = React.useState(false)
    const [popOverOpenData, setPopOverOpenData] = React.useState({})
    const [triggerRemoveContact, { isLoading: detailUpdateLoading }] = useDynamicMutationMutation();
    const [listData, setListData] = React.useState([])


    // pagination and endless scrolling states
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true)
    const loadMoreRef = React.useRef(null);

    // contact  listing
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.CONTACTS.CONTACTS_LIST,
        params: { page: page, limit: 10 },
        key: QueryKeys.CONTACT_LIST
    },
        { refetchOnMountOrArgChange: true }
    )
    // const listData = data?.data

    // data set with scrolling
    React.useEffect(() => {
        let mainData = data?.data?.result
        if (mainData?.length === 0) {
            setHasMore(false);
        } else {
            setListData((prevData) => {
                if (prevData?.length > 0) {                    
                    let newItems = mainData?.filter((item)=>!prevData.some((prevItem)=>prevItem?._id===item?._id))
                    return [...prevData, ...newItems]
                } else {
                    return mainData
                }
            });
        }
    }, [data])
    // endless scrolling
    useIntersectionObserver({
        targetRef: loadMoreRef,
        hasMore,
        isLoading,
        setPage,
    })

    // threeDot content
    const PopOverContent = ({ data }) => {
        setSelectData(data)
        const openFunction = (e, type) => {
            e.stopPropagation()
            setPopOverOpenData({});
            switch (type) {
                case 'view_access': {
                    navigate(`${RouterKeys.NON_Auth.CONTACTS.VIEW_ACCESS}?id=${data?._id}&name=${data?.first_name}`)
                }
                    break;
                case 'remove': {
                    setRemoveModal(true)
                }
                    break;
                case 'edit': {
                    navigate(`${RouterKeys.NON_Auth.CONTACTS.ADD_CONTACTS}?id=${data?._id}`)
                }
                    break;
                default:
                    CustomToast('e', "Something wrong")
            }
        }
        return (
            <div className="p-3 w-96">
                <div className="flex justify-end">
                    <ReactIcons.CloseIcon onClick={(e) => { e.stopPropagation(); setPopOverOpenData({}) }} />
                </div>
                <div className="text-center">
                    <AntdComponents.Typography className='titleSmall'>{data?.first_name} {data?.last_name}</AntdComponents.Typography>
                </div>
                <CustomDivider className={'mt-4'} />
                {
                    popoverArr?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center gap-5 mt-3" role='button' tabIndex={0}
                                onClick={(e) => openFunction(e, item?.type)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        openFunction(e, item?.type)
                                    }
                                }}
                            >
                                <AntdComponents.Avatar src={item?.icon} size={40} />
                                <AntdComponents.Typography className='titleSmall font-normal'>{item?.title}</AntdComponents.Typography>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const deleteClick = async () => {
        let payload = { contact_id: selectData?._id }
        let requestData = {
            endpoint: AUTH.CONTACTS.CONTACTS_DELETE,
            method: 'DELETE',
            body: payload,
        };

        try {
            const { data, error } = await triggerRemoveContact(requestData);
            if (data?.data) {
                let updateArr = listData?.filter((ele) => selectData?._id !== ele?._id)
                setListData(updateArr)
                CustomToast('s', data?.message);
                setSuccessModal(true)
                setRemoveModal(false)
                setTimeout(() => {
                    setSuccessModal(false)
                    setSelectData({})
                }, 1500)
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    return (
        <>
            <div className='content_common'>
                <div className="text-end mt-4">
                    <CustomButton title={'Add New Contact'} onClick={() => navigate(RouterKeys.NON_Auth.CONTACTS.ADD_CONTACTS)} />
                </div>
                <div className="text-center mt-3">
                    <AntdComponents.Avatar shape='square' src={STATIC_IMAGES.CONTACTS.CONTACTS} size={100} />
                    {
                        listData?.length > 0 ?
                            <>
                                <AntdComponents.Typography className='titleMedium text-center'>Contacts</AntdComponents.Typography>
                                <AntdComponents.Typography className='paragraph text-center mt-2'>These are the individuals who have access<br /> to view your documents.</AntdComponents.Typography>
                            </>
                            :
                            <AntdComponents.Typography className='titleMedium text-center'>No Contacts</AntdComponents.Typography>
                    }
                </div>
                <div className="">
                    <AntdComponents.Row gutter={[20, 20]} className='mt-5'>
                        {
                            isLoading ?
                                <>
                                    {
                                        [1, 2, 3]?.map((_, index) => {
                                            return (
                                                <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                                                    <SkeletonLoader />
                                                </AntdComponents.Col>
                                            )
                                        })
                                    }
                                </>
                                :
                                <>
                                    {
                                        listData?.map((item, index) => {
                                            return (
                                                <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index} className='cursor-pointer'>
                                                    <CardCommon data={item} image={item?.profile_pic ? `${fileUrl}${item?.profile_pic}` : <ReactIcons.profileIcon className='text-DarkGrey h-10' style={{ fontSize: 70 }} />} size={50} shape={'circle'} name={`${item?.first_name} ${item?.last_name}`} popOverOpenData={popOverOpenData} setPopOverOpenData={setPopOverOpenData} PopOverContent={PopOverContent} byDetailPage={true} />
                                                </AntdComponents.Col>
                                            )
                                        })
                                    }
                                </>
                        }
                    </AntdComponents.Row>
                </div>

                {/* remove modal */}
                {
                    removeModal &&
                    <AntdComponents.Modal centered open={removeModal} onCancel={() => { setRemoveModal(false); setSelectData({}) }} footer={false} width={500} closeIcon={<ReactIcons.CloseIcon />}>
                        <div className="text-center my-10">
                            <AntdComponents.Image preview={false} src={STATIC_IMAGES.ALERT_ICON} alt="deletIcon" height={90} width={'auto'} />
                            <AntdComponents.Typography className="titleSmall text_truncate">Are you sure want to remove <br />{selectData?._id && `${selectData?.first_name}'s contact.`}</AntdComponents.Typography>
                            <div className="mt-4">
                                <CustomButton title={'Remove contact'} isLoading={detailUpdateLoading} onClick={() => deleteClick()} />
                                <OutlineButton title={'Keep contact'} className={'text-Purple bg-White'} classNameDiv='m-auto mt-5 w-full md:w-64' onClick={() => { setRemoveModal(false); setSelectData({}) }} />
                            </div>
                        </div>
                    </AntdComponents.Modal>
                }
                {/* success modal */}
                {
                    successModal &&
                    <SuccessModal
                        successModal={successModal}
                        setSuccessModal={setSuccessModal}
                        setSelectData={setSelectData}
                        msg={
                            selectData?._id
                            && `${selectData?.first_name}'s contact was successfully removed from your account.`
                        }
                    />

                }
            </div>
            {/* scrolling */}
            {hasMore && <div ref={loadMoreRef} className=''></div>}
        </>

    )
}

export default Contacts
