import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../../../utils/ReactIcons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'
import CardCommon from '../../../components/CardCommon'
import { fileNameAndUrl } from '../../../utils/Constant'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import CustomButton from '../../../components/CustomButton'
import OutlineButton from '../../../components/OutlineButton'
import CustomToast from '../../../utils/CustomToast'
import SuccessModal from '../../../components/SuccessModal'

const ViewAccessLevel = () => {
    const [removeModal, setRemoveModal] = React.useState(false)
    const [successModal, setSuccessModal] = React.useState(false)
    const [selectData, setSelectData] = React.useState({})
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const contactId = searchParam.get('id')
    const accessUserName = searchParam.get('name')
    // delete access view
    const [triggerRemoveContact, { isLoading: detailUpdateLoading }] = useDynamicMutationMutation();
    // access level  listing
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.CONTACTS.CONTACTS_ACCESS_VIEW,
        params: { contact_id: contactId },
        key: QueryKeys.CONTACT_ACCESS_LEVEL
    },
        { skip: !contactId, refetchOnMountOrArgChange: true }
    )
    const listData = data?.data
    console.log('listData', listData);
    // delete or remove access level
    const deleteClick = async (type) => {
        let payload = {
            contact_id: contactId,
        }
        if (type === 'single') {
            payload.type = selectData['folder_name'] ? 'folder' : selectData['document_name'] && 'document'
            payload.file_id = selectData?._id
        }
        let requestData = {
            endpoint: type === 'single' ? AUTH.CONTACTS.CONTACTS_ACCESS_REMOVE : AUTH.CONTACTS.CONTACTS_ACCESS_REMOVE_ALL,
            method: 'POST',
            body: payload,
            key: QueryKeys.CONTACT_ACCESS_LEVEL
        };

        try {
            const { data, error } = await triggerRemoveContact(requestData);
            if (data?.data) {
                CustomToast('s', data?.message);
                setRemoveModal(false)
                setSuccessModal(true)
                setTimeout(() => {
                    setSuccessModal(false)
                    setSelectData({})
                }, 1500)
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }

    return (
        <div className='content_common'>
            <div className="flex items-center justify-between mt-5">
                <ReactIcons.BackIcon onClick={() => navigate(RouterKeys.NON_Auth.CONTACTS.CONTACTS)} />
                <div className="">
                    <AntdComponents.Typography className='titleMedium text-center'>Current Access</AntdComponents.Typography>
                </div>
                <p />
            </div>
            <div className="">
                {
                    isLoading ?
                        <AntdComponents.Row gutter={[20, 20]}>
                            {
                                [1, 2, 3]?.map((_, index) => {
                                    return (
                                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                                            <SkeletonLoader />
                                        </AntdComponents.Col>
                                    )
                                })
                            }
                        </AntdComponents.Row>
                        :
                        <>
                            {
                                listData?.length === 0 ?
                                    <AntdComponents.Typography className='titleSmall text-center mt-5 ps-8'>No Access Found!</AntdComponents.Typography>
                                    : <>
                                        <div className="text-center mt-1">
                                            <AntdComponents.Typography className='paragraph text-center'>These are the documents {accessUserName}&apos; can view.</AntdComponents.Typography>
                                            <CustomButton title={'Remove all access'} className={'mt-4'} onClick={() => deleteClick('all')} />
                                        </div>
                                        <AntdComponents.Row gutter={[20, 20]} className='mt-7'>
                                            {
                                                listData?.map((item, index) => {
                                                    let { name, url } = fileNameAndUrl(item)
                                                    return (
                                                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index} className='cursor-pointer'>
                                                            <CardCommon data={item} image={url} size={50} shape={'square'} name={name} byDetailPage={true} accessLevel={true} setPopOverOpenData={setSelectData} />
                                                        </AntdComponents.Col>
                                                    )
                                                })
                                            }
                                        </AntdComponents.Row>
                                    </>
                            }
                        </>
                }
            </div>
            {/* remove modal */}
            {
                (removeModal || selectData?._id) &&
                <AntdComponents.Modal centered open={selectData?._id ? true : false || removeModal} onCancel={() => { setRemoveModal(false); setSelectData({}) }} footer={false} width={500} closeIcon={<ReactIcons.CloseIcon />}>
                    <div className="text-center my-10">
                        <AntdComponents.Image preview={false} src={STATIC_IMAGES.ALERT_ICON} alt="deleteIcon" height={90} width={'auto'} />
                        <AntdComponents.Typography className="titleSmall text_truncate">Are you sure want to remove <br />{selectData?._id && `${accessUserName}'s access to view ${selectData?.document_name || selectData?.folder_name}.`}</AntdComponents.Typography>
                        <div className="mt-4">
                            <CustomButton title={'Confirm'} isLoading={detailUpdateLoading} onClick={() => deleteClick('single')} />
                            <OutlineButton title={'Cancel'} className={'text-Purple bg-White'} classNameDiv='m-auto mt-5 w-full md:w-64' onClick={() => { setRemoveModal(false); setSelectData({}) }} />
                        </div>
                    </div>
                </AntdComponents.Modal>
            }
            {/* success modal */}
            {
                successModal &&
                <SuccessModal
                    successModal={successModal}
                    setSuccessModal={setSuccessModal}
                    setSelectData={setSelectData}
                    msg={
                        selectData?._id
                        &&
                        <>
                            {accessUserName} &apos; access to {selectData?.document_name || selectData?.folder_name} was  <br />successfully removed from viewing
                        </>
                    }
                />

            }
        </div>
    )
}

export default ViewAccessLevel
