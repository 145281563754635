import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import CustomButton from '../../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { RouterKeys } from '../../../Routes/RouterKeys'


const Subscription = () => {
    const navigate= useNavigate()
    return (
        <div className='content_common' >
            <AntdComponents.Typography className='titleMedium text-center'>Getting Started</AntdComponents.Typography>
            <AntdComponents.Typography className='paragraph text-center'>How your free trial works</AntdComponents.Typography>
            <div className="flex relative mt-5">
                <div className="h-[450px] w-6 bg-GreyLight4 rounded-full" />
                {/* 1st tier */}
                <div className="h-28 flex gap-3 absolute">
                    <div className="bg-DarkPurple w-6 rounded-full pt-2">
                        <AntdComponents.Avatar src={STATIC_IMAGES.SUBSCRIPTION.BANK_SAFE} shape='square' size={25} />
                    </div>
                    <div className="mt-2">
                        <AntdComponents.Typography className='titleSmall'>Today</AntdComponents.Typography>
                        <AntdComponents.Typography className='description font-semibold text-Black mt-3'>Start with instant access to VaultPD to <br/> create your own Personal Digital Vault</AntdComponents.Typography>
                    </div>
                </div>
                {/* 1st tier */}
                <div className="h-28 flex gap-3 absolute top-[40%]">
                    <div className="bg-DarkPurple w-6 rounded-full pt-2">
                        <AntdComponents.Avatar src={STATIC_IMAGES.SUBSCRIPTION.NOTIFICATION_WHITE} shape='square' size={25} />
                    </div>
                    <div className="mt-2">
                        <AntdComponents.Typography className='titleSmall'>Day 3</AntdComponents.Typography>
                        <AntdComponents.Typography className='description font-semibold text-Black mt-3'>A  notification will be sent to let you<br/> know your trial is ending soon.</AntdComponents.Typography>
                    </div>
                </div>
                {/* 1st tier */}
                <div className="h-28 flex gap-3 absolute bottom-0">
                    <div className="bg-DarkPurple w-6 rounded-full pt-2">
                        <AntdComponents.Avatar src={STATIC_IMAGES.SUBSCRIPTION.STAR} shape='square' size={25} />
                    </div>
                    <div className="mt-2">
                        <AntdComponents.Typography className='titleSmall'>Day 4</AntdComponents.Typography>
                        <AntdComponents.Typography className='description font-semibold text-Black mt-3'>Your subscription starts. Cancel<br/> anytime before the trial ends.</AntdComponents.Typography>
                    </div>
                </div>
            </div>
            
            <div className="mt-5 text-center">
                <AntdComponents.Typography className='titleSmall'>10 days free, then $20 a month</AntdComponents.Typography>
                <CustomButton title={'Continue'} className={'my-4'}/>
                <AntdComponents.Typography className='titleSmall cursor-pointer' onClick={()=>navigate(`${RouterKeys.NON_Auth.SUBSCRIPTION.ALL_PLANS}`)}>See all plans</AntdComponents.Typography>
            </div>
        </div>
    )
}

export default Subscription

