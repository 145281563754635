import { useEffect } from 'react';

const useIntersectionObserver = ({
    targetRef,
    hasMore,
    isLoading,
    setPage = () => { },
    threshold = 0.1,
}) => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && hasMore && !isLoading) {
                    // setLoadMore(true)
                    setPage((prevPage) => prevPage + 1);

                }
            },
            { threshold: threshold }
        );
 
        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [hasMore, isLoading]);
};

export default useIntersectionObserver;
