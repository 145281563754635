import React from 'react'
import { STATIC_IMAGES } from '../../utils/StaticImages'
import * as AntdComponents from 'antd'
import { RouterKeys } from '../../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { NON_AUTH } from '../../utils/Endpoints'
import { useMutation } from '../../redux/hook/useApi'
import CustomToast from '../../utils/CustomToast'
import { EmailStore } from '../../redux/slices/authSlice'
import CommonHeader from './CommonHeader'
import CustomButton from '../../components/CustomButton'
const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleMutation, isLoading } = useMutation();
    const handleSubmit = async (values) => {
        let payload = {
            "email": values?.email
        }
        const { data, error } = await handleMutation({
            endpoint: NON_AUTH.FORGET_PASSWORD,
            method: 'POST',
            body: payload,
        });
        console.log('data?.data', data);

        if (data) {
            CustomToast('s', data?.message)
            dispatch(EmailStore(values.email.trim()));
            navigate(`/${RouterKeys.Auth.OTP_VERIFY}?page-type=forgot`)
        } else {
            CustomToast('e', error?.data?.message)
        }
    }
    return (
        <div className='auth-main-div'>
            <CommonHeader />
            <div className='text-center auth-form rounded-10 shadow-formShadow p-4'>
                <div className="inputs-container">
                    <AntdComponents.Typography className='titleMedium text-center mb-4'>Forgot Password</AntdComponents.Typography>
                    <AntdComponents.Form name="Auth" initialValues={{ remember: true, }} onFinish={handleSubmit} requiredMark={false} className='mt-2' layout='vertical'>
                        <AntdComponents.Form.Item name="email" autoComplete="off"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                                {
                                    required: true,
                                    message: 'Please Enter your email!',
                                },
                            ]}>
                            <AntdComponents.Input className='input-box' placeholder='name@domain.com' prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.EMAIL} size={30} preview={false} shape='square' />} />
                        </AntdComponents.Form.Item>
                        <div className="mt-14">
                            <CustomButton title='Submit' className='mb-4' isLoading={isLoading} htmlType='submit' />
                        </div>
                    </AntdComponents.Form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
